import React, {FC, useCallback, useState} from 'react';
import InputField from "../widgets/input-field";
import {useFormik} from "formik";
import DriverSchema from "../../utils/form-validation/driver-schema";
import OptionsSelect from "../widgets/options-select";
import {citiesOptions, genderOptions} from "../../utils/helpers";
import {useCreateDriverMutation, useUpdateDriverMutation} from "../../redux/services/driversApi";
import {DriverFormProps, DriverProps} from "../../types/onboard-forms/driver-props";
import ImageUpload from "../widgets/image-upload";
import OnboardingNext from "../widgets/onboarding-next";
import AddressForm from "./address-form";
import {Alert, Checkbox} from "antd";
import statics from "../../utils/constants/static-texts.json";
import {countries, countriesOptions} from "../../utils/constants/countries";

interface AddDriverProps {
    closeDialog?: any
    editMode?: boolean
    editableDriver?: DriverFormProps
}

const EditDriver: FC<AddDriverProps> = ({closeDialog, editableDriver, editMode}) => {
    const [createDriver, {isLoading: creating}] = useCreateDriverMutation();
    const [updateDriver] = useUpdateDriverMutation();
    const [selected, setSelected] = useState('idNumber')
    const [showErrors, setShowErrors] = useState(true)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>('')

    const [successMessage, setSuccessMessage] = useState('')

    const handleSubmit = async (values: DriverProps) => {
        const formData: DriverFormProps = {
            firstName: values.firstName,
            surName: values.surName,
            idNumber: values.idNumber,
            phone: `${values.phone}`,
            address: {
                erf: values.erf,
                suburb: values.suburb,
                street: values.street
            },
            gender: values.gender,
            idDocument: {
                front: values.idFront,
                back: values.idBack
            },
            driverLicense: {
                front: values.dlFront,
                back: values.dlBack
            },
            country: values.country,
            city: values.city

        }
        setLoading(true)
        if (editMode) {
            try {
                await updateDriver({id: editableDriver?._id, formData}).then((response: any) => {
                    console.log(response)
                    setLoading(false)
                    setSuccessMessage("Updated successfully.")
                    if (closeDialog) {
                        setTimeout(closeDialog, 2000)
                    }
                })
            } catch (e: any) {
                setLoading(false)
                setShowErrors(true)
                setErrorMessage("Something went wrong, please try again")
            }
        } else {
            try {
                await createDriver({...formData, phone: formData.phone}).then((response: any) => {
                    console.log(response)
                })
            } catch (e) {
                console.log(e)
            }
        }
    }


    const driverFormik = useFormik({
        validationSchema: DriverSchema,
        validateOnMount: true,
        initialValues: {
            firstName: editableDriver ? editableDriver?.firstName : '',
            surName: editableDriver ? editableDriver?.surName : '',
            idNumber: editableDriver ? editableDriver?.idNumber : '',
            phone: editableDriver ? editableDriver?.phone : '',
            erf: editableDriver ? editableDriver?.address?.erf : '',
            street: editableDriver ? editableDriver?.address?.street : '',
            suburb: editableDriver ? editableDriver?.address?.suburb : '',
            gender: editableDriver ? editableDriver?.gender : '',
            dlFront: editableDriver ? editableDriver?.driverLicense?.front : '',
            dlBack: editableDriver ? editableDriver?.driverLicense?.back : '',
            idBack: editableDriver ? editableDriver?.idDocument?.back : '',
            idFront: editableDriver ? editableDriver?.idDocument?.front : '',
            country: editableDriver ? editableDriver?.country : '',
            city: editableDriver ? editableDriver?.city : ''
        },
        onSubmit: (values: DriverProps) => {
            handleSubmit(values)
        },
    })

    const setInputValue = useCallback(
        (key: string, value: any) =>
            driverFormik.setValues({
                ...driverFormik.values,
                [key]: value
            }),
        [driverFormik]
    )


    const buttonText = editMode ? 'Update driver' : 'Save'


    return (
        <form onSubmit={driverFormik.handleSubmit}>
            <div className="w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div className="block">
                        <InputField
                            value={driverFormik.values.firstName}
                            label={`First name`}
                            handleChange={(value: string) => setInputValue('firstName', value)}
                            inputType="text"
                            placeHolder="e.g: Jack"
                        />
                        {showErrors && <small>{driverFormik.errors.firstName}</small>}
                    </div>
                    <div className="block">
                        <InputField
                            value={driverFormik.values.surName}
                            label={`Surname`}
                            placeHolder="e.g: Smith"
                            handleChange={(value: string) => setInputValue('surName', value)}
                            inputType="text"
                        />
                        {showErrors && <small>{driverFormik.errors.surName}</small>}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div className="block">
                        <InputField
                            value={driverFormik.values.phone}
                            label="Phone number"
                            handleChange={(value: string) => setInputValue('phone', value)}
                            inputType="phone_number"
                        />
                        {showErrors && <small>{driverFormik.errors.phone}</small>}
                    </div>
                    <div className="block">
                        <OptionsSelect
                            options={genderOptions}
                            defaultValue={{
                                value: editableDriver?.gender ? editableDriver?.gender : '',
                                label: editableDriver?.gender ? editableDriver?.gender : ''
                            }}
                            label="Gender"
                            onChanged={(value: string) => setInputValue('gender', value)}
                        />
                        {showErrors && <small>{driverFormik.errors.gender}</small>}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div className="block">
                        <OptionsSelect
                            options={countriesOptions}
                            defaultValue={{
                                value: editableDriver?.country ? editableDriver?.country : '',
                                label: editableDriver?.country ? editableDriver?.country : ''
                            }}
                            label="Country"
                            onChanged={(value: string) => setInputValue('country', value)}
                        />
                        {showErrors && <small>{driverFormik.errors.country}</small>}
                    </div>
                    <div className="validated_field_col">
                        <OptionsSelect
                            options={citiesOptions(driverFormik.values.country === countries.Zambia ? statics.zambia_cities : driverFormik.values.country === countries.Namibia ? statics.namibia_cities : driverFormik.values.country === countries.South_Africa ? statics.south_africa_cities : [])}
                            defaultValue={{
                                label: driverFormik.values.city,
                                value: driverFormik.values.city
                            }}
                            disabled={driverFormik.values.country === ''}
                            label="Select city"
                            onChanged={(value: string) => driverFormik.setFieldValue('city', value)}
                        />
                        <small>{driverFormik.errors.city}</small>
                    </div>
                </div>
                <div className="block mt-2">
                    <InputField
                        value={driverFormik.values.idNumber}
                        label={`ID or passport number`}
                        placeHolder="e.g: 13010030000"
                        handleChange={(value: number) => setInputValue('idNumber', value)}
                        inputType="text"
                    />
                    <div className="flex justify-start gap-3 items-center">
                        <Checkbox
                            checked={selected === 'idNumber'}
                            onChange={() => setSelected('idNumber')}>
                            Identification number
                        </Checkbox>
                        <Checkbox
                            checked={selected === 'passportNumber'}
                            onChange={() => setSelected('passportNumber')}>
                            Passport number
                        </Checkbox>
                    </div>
                    {showErrors && <small>{driverFormik.errors.idNumber}</small>}
                </div>

                <div className="w-full py-2">
                    <h3 className="font-medium text-xl mt-5">Address</h3>
                    <AddressForm
                        erf={driverFormik.values.erf}
                        street={driverFormik.values.street}
                        suburb={driverFormik.values.suburb}
                        onErfChange={(value: string) => driverFormik.setFieldValue('erf', value)}
                        onStreetChange={(value: string) => driverFormik.setFieldValue('street', value)}
                        onSuburbChange={(value: string) => driverFormik.setFieldValue('suburb', value)}
                        erfError={driverFormik.errors.erf}
                        streetError={driverFormik.errors.street}
                        suburbError={driverFormik.errors.suburb}
                        country={driverFormik.values.country}
                        city={driverFormik.values.city}
                    />
                </div>
            </div>
            <div className="my-5">
                <div className="bg-sky-600 p-3 my-3 rounded-md text-white">
                    <strong>IDENTIFICATION / PASSPORT</strong>
                </div>
                <div className="grid grid-cols-2 gap-3">
                    <div className="flex flex-col">
                        <ImageUpload
                            currentImage={driverFormik.values.idFront}
                            onLinkReady={(link: string) => driverFormik.setFieldValue('idFront', link)}
                            label="Front"
                        />
                        {showErrors && <small className='error_text'>{driverFormik.errors.idFront}</small>}
                    </div>
                    <div className="flex flex-col">
                        <ImageUpload
                            currentImage={driverFormik.values.idBack}
                            onLinkReady={(link: string) => driverFormik.setFieldValue('idBack', link)}
                            label="Back"
                        />
                        {showErrors && <small className='error_text'>{driverFormik.errors.idBack}</small>}
                    </div>
                </div>
            </div>
            <div>
                <div className="bg-sky-600 p-3 my-3 rounded-md text-white">
                    <strong>DRIVER LICENSE</strong>
                </div>
                <div className="grid grid-cols-2 gap-3">
                    <div className="flex flex-col">
                        <ImageUpload
                            currentImage={driverFormik.values.dlFront}
                            onLinkReady={(link: string) => driverFormik.setFieldValue('dlFront', link)}
                            label="Front"
                        />
                        {showErrors && <small className='error_text'>{driverFormik.errors.dlFront}</small>}
                    </div>
                    <div className="flex flex-col">
                        <ImageUpload
                            currentImage={driverFormik.values.dlBack}
                            onLinkReady={(link: string) => driverFormik.setFieldValue('dlBack', link)}
                            label="Back"
                        />
                        {showErrors && <small className='error_text'>{driverFormik.errors.dlBack}</small>}
                    </div>
                </div>
            </div>
            <section>
                {
                    errorMessage && (
                        <div className="my-4">
                            <Alert message={errorMessage} type="error" showIcon/>
                        </div>
                    )
                }
                {
                    successMessage && (
                        <div className="my-4">
                            <Alert message={successMessage} type="success" showIcon/>
                        </div>
                    )
                }
            </section>
            <section className="flex justify-between items-center flex-col w-full mt-10">
                <div className="w-full flex flex-end justify-end">
                    <div className="w-[200px]">
                        <button
                            className="primary_button"
                            type="submit"
                            disabled={creating || loading}
                        >
                            {loading ? 'Please wait ...' : buttonText}
                        </button>
                    </div>
                    {closeDialog === null && (
                        <OnboardingNext step="Add your Vehicles"/>
                    )}
                </div>
            </section>
        </form>
    )
}

export default EditDriver;