import React, {useState} from 'react'
import Terms from "./terms";
import {Modal} from "antd";


const DashboardFooter = () => {
    const currentYear = new Date().getFullYear()
    const [showTerms, setShowTerms] = useState(false)

    const RRSS = {
        instagram: 'https://www.instagram.com/jabu.africa/?hl=en',
        facebook: 'https://www.facebook.com/jabuafrica/',
        linkedin: 'https://www.linkedin.com/company/jabu/',
        twitter: 'https://twitter.com/jabulogistics?lang=en',
    };

    return (
        <div className="p-4 text-center mt-20 bg-gray-100 flex justify-between items-center">
            <span>© JabuConnect {currentYear}</span>
            <div>
                <span onClick={() => setShowTerms(true)}
                      className="text-jc-primary hover:text-jc-secondary cursor-pointer">Terms and conditions.
                </span>
                <div className="py-4">
                    <div className="flex flex-row">
                        <a href={RRSS.instagram} target="_blank" rel="noreferrer">
            <span
                className="border-2 border-black rounded-full w-6 h-6 md:w-8 md:h-8 flex items-center justify-center cursor-pointer">
              <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px">
                <path
                    d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z"/>
              </svg>
            </span>
                        </a>
                        <a href={RRSS.facebook} target="_blank" rel="noreferrer">
            <span
                className="border-2 border-black rounded-full w-6 h-6 md:w-8 md:h-8 flex items-center justify-center ml-2 cursor-pointer">
              <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="16px" height="16px">
                <path
                    d="M12,27V15H8v-4h4V8.852C12,4.785,13.981,3,17.361,3c1.619,0,2.475,0.12,2.88,0.175V7h-2.305C16.501,7,16,7.757,16,9.291V11 h4.205l-0.571,4H16v12H12z"/>
              </svg>
            </span>
                        </a>
                        <a href={RRSS.linkedin} target="_blank" rel="noreferrer">
            <span
                className="border-2 border-black rounded-full w-6 h-6 md:w-8 md:h-8 flex items-center justify-center ml-2 cursor-pointer">
              <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16px" height="16px">
                <path
                    d="M 8.6425781 4 C 7.1835781 4 6 5.181625 6 6.640625 C 6 8.099625 7.182625 9.3085938 8.640625 9.3085938 C 10.098625 9.3085938 11.283203 8.099625 11.283203 6.640625 C 11.283203 5.182625 10.101578 4 8.6425781 4 z M 21.535156 11 C 19.316156 11 18.0465 12.160453 17.4375 13.314453 L 17.373047 13.314453 L 17.373047 11.310547 L 13 11.310547 L 13 26 L 17.556641 26 L 17.556641 18.728516 C 17.556641 16.812516 17.701266 14.960938 20.072266 14.960938 C 22.409266 14.960937 22.443359 17.145609 22.443359 18.849609 L 22.443359 26 L 26.994141 26 L 27 26 L 27 17.931641 C 27 13.983641 26.151156 11 21.535156 11 z M 6.3632812 11.310547 L 6.3632812 26 L 10.923828 26 L 10.923828 11.310547 L 6.3632812 11.310547 z"/>
              </svg>
            </span>
                        </a>
                        <a href={RRSS.twitter} target="_blank" rel="noreferrer">
            <span
                className="border-2 border-black rounded-full w-6 h-6 md:w-8 md:h-8 flex items-center justify-center ml-2 cursor-pointer">
              <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="14px" height="14px">
                <path
                    d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"/>
              </svg>
            </span>
                        </a>
                    </div>
                </div>
            </div>
            <Modal
                footer={null}
                title="JabuConnect Program Terms of Use"
                width={1000} open={showTerms}
                onCancel={() => setShowTerms(false)}
            >
                <Terms/>
            </Modal>
        </div>
    )
}

export default DashboardFooter;