import React, {FC} from 'react';
import {MdInfo} from "react-icons/md";
import {Tooltip} from "antd";

interface ValueBoxProps {
    title: string
    value: string | number | undefined
    tooltipText?: string
}

const ValueBox: FC<ValueBoxProps> = ({title, value, tooltipText}: ValueBoxProps) => {
    return (
        <div className="value_box">
            <div className="flex justify-between items-center">
                <p className="card_title">{title}</p>
                <div>
                    {tooltipText && (
                        <Tooltip title={tooltipText}>
                            <MdInfo className="w-5 h-5 -mt-3 cursor-pointer text-gray-400"/>
                        </Tooltip>
                    )}
                </div>
            </div>
            <p className="value">{value}</p>
        </div>
    )
}

export default ValueBox;