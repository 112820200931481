import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import urls from "../utils/constants/urls";

const VerificationApiConfig = fetchBaseQuery({
    baseUrl: urls.root_url,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('verificationToken');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
            headers.set('Content-Type', 'application/json')
        }
    }
})

export default VerificationApiConfig;