export enum countries {
    Namibia = 'Namibia',
    South_Africa = 'South Africa',
    Zambia = 'Zambia'
}

export const countriesOptions = [
    {
        value: countries.Zambia,
        label: countries.Zambia
    },
    {
        value: countries.Namibia,
        label: countries.Namibia
    },
    {
        value: countries.South_Africa,
        label: countries.South_Africa
    }
]