import {useAppDispatch, useAppSelector} from "../redux/user-redux";
import {updateNavState} from "../redux/steps-navigation/steps-nav-slice";
import {selectOnboardingState} from "../redux/onboarding/onboarding-slice";

export const useUpdateOnboardStep = () => {
    const dispatch = useAppDispatch();
    const {doneSteps} = useAppSelector(selectOnboardingState)

    const increment = (stepIndex: number) => dispatch(updateNavState({
        currentStepIndex: stepIndex,
        doneSteps: doneSteps.concat(stepIndex).filter((item, index, self) => self.indexOf(item) === index)
    }))

    return {increment};
}