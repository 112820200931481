import * as yup from 'yup';

const DriverSchema = yup.object().shape({
    firstName: yup.string().min(4).required('Name is required'),
    surName: yup.string(),
    idNumber: yup.string().min(6).required('ID number is required'),
    phone: yup.string().required('Phone number is required'),
    gender: yup.string().required('gender is required'),
    dlFront: yup.string().required('Please upload the front side of driver license'),
    dlBack: yup.string().required('Please upload the back side of driver license'),
    idBack: yup.string().required('Please upload the front side of driver ID document'),
    idFront: yup.string().required('Please upload the front side of driver ID document'),
    erf: yup.string().required('erf is required'),
    suburb: yup.string().required('suburb is required'),
    street: yup.string().required('street is required')
})
export default DriverSchema;