import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import moment from "moment";
import {RootState} from "../store";

interface FilterSliceProps {
    fromDate: any
    toDate: any
}

const defaultState: FilterSliceProps = {
    fromDate: moment().format(),
    toDate: moment().format(),
}

const filterSlice = createSlice({
    name: 'filters',
    initialState: defaultState,
    reducers: {
        updateFilterValues(state, action: PayloadAction<Record<any, any>>) {
            console.log(action.payload)
            return {
                ...state,
                ...action.payload
            }
        }
    }
})
export  const selectFilterState = (state:RootState) => state.filters
export const {updateFilterValues} = filterSlice.actions
export default filterSlice.reducer