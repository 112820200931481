import React, {FC, useState} from 'react';
import DashboardWrapper from "../../Components/widgets/dashboard_wrapper";
import DriversTable from "../../Components/widgets/drivers-table";
import PrimaryButton from "../../Components/widgets/primary-button";
import Dialog from "@mui/material/Dialog";
import EditDriver from "../../Components/forms/edit-driver";
import {MdClose} from "react-icons/md";
import {DialogContent, DialogTitle} from "@mui/material";
import {DriverFormProps} from "../../types/onboard-forms/driver-props";
import {UseDrivers} from "../../hooks/useDrivers";
import {Card, Pagination} from "antd";

const Drivers: FC = () => {
    const [editableDriver, setEditableDriver] = useState<DriverFormProps>()
    const [showEditDriver, setShowEditDriver] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);

    const {drivers: data, loadingDrivers: isLoading, refetchDrivers, totalDrivers, restartPaging} = UseDrivers()

    const handleCloseModal = () => {
        setShowEditDriver(false)
        refetchDrivers()
    }

    const handleEditing = (id: string) => {
        const found = data.find((item: any) => item._id === id)
        if (found) {
            setEditableDriver(found)
            setShowEditDriver(true)
        }
    }


    const handlePagination = (size: number) => {
        setCurrentPage(size)
        if (size === 1) {
            restartPaging()
        } else {
            refetchDrivers()
        }
    }

    return (
        <DashboardWrapper>
            <div
                className="flex justify-between flex-col md:flex-row md:items-center gap-4 mb-4 bg-white py-3 px-6 md:px-14">
                <span className="page_section_title">Drivers</span>
                <div>
                    <PrimaryButton
                        buttonText="Add new driver"
                        onClicked={() => window.location.href = '/add-driver'}
                        disabled={false}
                    />
                </div>
            </div>
            <div className="md:px-14 px-6">
                <Card title={`${totalDrivers} ${totalDrivers === 1 ? 'driver' : 'drivers'}`}>
                    <DriversTable
                        refetchDrivers={refetchDrivers}
                        onOpenEdit={(id: string) => handleEditing(id)}
                        data={data}
                        loading={isLoading}
                    />

                    <div className="flex justify-center items-center p-4">
                        <Pagination onChange={(size) => handlePagination(size)} current={currentPage}
                                    total={totalDrivers}
                        />
                    </div>

                </Card>
            </div>
            <Dialog
                fullWidth
                open={showEditDriver}
                onClose={handleCloseModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    <div className="flex justify-between items-center cursor-pointer">
                        {editableDriver ? 'Update driver details' : 'Add new driver'}
                        <MdClose onClick={handleCloseModal}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <EditDriver
                        editMode={!!editableDriver}
                        editableDriver={editableDriver}
                        closeDialog={handleCloseModal}
                    />
                </DialogContent>
            </Dialog>
        </DashboardWrapper>
    )
}

export default Drivers;