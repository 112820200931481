import React, {useEffect, useState} from 'react';
import InputField from "../widgets/input-field";
import OptionsSelect from "../widgets/options-select";
import {namibiaSuburbs} from "../../utils/helpers";
import MainApi from "../../services/axiosInstance";

interface AddressFormProps {
    erf: string
    street: string
    suburb: string
    onErfChange: (value: string) => void
    onStreetChange: (value: string) => void
    onSuburbChange: (value: string) => void
    erfError?: string | string[]
    streetError?: string | string[]
    suburbError?: string | string[]
    country: string
    city: string
}


const AddressForm: React.FC<AddressFormProps> = ({
                                                     erf,
                                                     street,
                                                     onErfChange,
                                                     onStreetChange,
                                                     country,
                                                     city,
                                                     suburb,
                                                     onSuburbChange
                                                 }: AddressFormProps) => {

    const [suburbsOptions, setSuburbsOptions] = useState<{ label: string, value: string }[]>([])
    const [loadingSuburbs, setLoadingSuburbs] = useState(true)

    const fetchSuburbs = async (cityName: string) => {
        const res = await MainApi.get(`/location/suburbs?cityName=${cityName}`)
        if (res && res.data.message === 'success') {
            const options = res.data.result.map((sub: { name: string }) => ({label: sub.name, value: sub.name}))
            setSuburbsOptions(options)
            setLoadingSuburbs(false)
        }
    }

    useEffect(() => {
        if (city) {
            fetchSuburbs(city).then(() => console.log('suburbs found'))
        }
    }, [city])

    return (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="validated_field_col">
                {suburbsOptions.length > 0 ? <OptionsSelect
                    defaultValue={{
                        value: suburb,
                        label: suburb
                    }}
                    options={suburbsOptions}
                    label="Suburb"
                    onChanged={(value: string) => onSuburbChange(value)}
                /> : <>
                    <InputField
                        value={suburb}
                        label="Suburb"
                        placeHolder="e.g. Katutura"
                        handleChange={(value: string) => onSuburbChange(value)}
                        inputType="text"
                    />
                </>}
            </div>
            <div className="validated_field_col">
                <InputField
                    value={erf}
                    label="ERF"
                    placeHolder="e.g. 1401"
                    handleChange={(value: string) => onErfChange(value)}
                    inputType="text"
                />
            </div>
            <div className="validated_field_col">
                <InputField
                    value={street}
                    label="Street"
                    placeHolder="e.g. Tunga street"
                    handleChange={(value: string) => onStreetChange(value)}
                    inputType="text"
                />
            </div>
        </div>
    )
}

export default AddressForm;