import MainApi from "./axiosInstance";
import Urls from "../utils/constants/urls";

class VehicleService {
    addVehicle(payload: any) {
        return MainApi.post(Urls.Vehicles, payload)
    }

    getAllVehicles(afterId?: string) {
        let url = Urls.Vehicles
        if (afterId) {
            url += `?afterId=${afterId}`
        }
        return MainApi.get(url)
    }
}

export default new VehicleService()