import React, {FC} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import images from "../../utils/constants/assets";
import {Link} from "react-router-dom";
import {MdNotifications, MdPerson, MdSettings} from "react-icons/md";
import {IconButton, Tooltip} from "@mui/material";
import {logoutUser} from "../../utils/helpers";

const ProfileDropDown = () => {
    return (
        <PopupState variant="popover" popupId="demo-popup-menu" disableAutoFocus={false}>
            {(popupState) => (
                <React.Fragment>
                    <MdPerson className="cursor-pointer" {...bindTrigger(popupState)}/>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={logoutUser}>Logout</MenuItem>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    )
}


const TopBar: FC = () => {
    return (
        <div className="top_bar_wrapper">
            <div className="top_bar_logo_container">
                <img className="max-w-[98px]" src={images.mainLogo} alt="jabu-driver"/>
            </div>
            <div className="top_bar_icons_container">
                <Tooltip title="Notifications">
                    <IconButton>
                        <MdNotifications className="text-gray-400 w-5"/>
                    </IconButton>
                </Tooltip>
                <Link to="/settings">
                    <MdSettings/>
                </Link>
                <ProfileDropDown/>
            </div>
        </div>
    )
}


export default TopBar;