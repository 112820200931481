import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import styles from './authentication.module.scss'
import InputField from '../../Components/widgets/input-field'
import {Checkbox, Modal, Button} from 'antd';
import Alert from '@mui/material/Alert'
import {useRegisterUserMutation} from "../../redux/services/authenticationApi";
import VerifyOtpForm from "../../Components/features/authentication/verify-otp-form";
import AuthWrapper from "./auth-wrapper";
import Lottie from 'lottie-react';
import successAnimationData from '../../utils/constants/lotties-animations/79952-successful.json'
import {useFormik} from "formik";
import PrimaryButton from "../../Components/widgets/primary-button";
import {SignupFormProps} from "../../types/signup-form";
import SignupSchema from "../../utils/form-validation/signup-schema";
import Terms from "../../Components/features/terms";

const SignUp: React.FC = () => {
    const [acceptedTerms, setAcceptedTerms] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>()
    const [showVerification, setShowVerification] = React.useState<boolean>(false)
    const [userVerified, setUserVerified] = useState<boolean>(false)
    // for only sending phones and wait for otp code.
    const [registerUser, {isLoading: registeringUser}] = useRegisterUserMutation();
    const [showError, setShowError] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const navigate = useNavigate();

    const handleSignUp = (values: SignupFormProps) => {
        setErrorMessage('')
        const payload = {
            firstName: values.firstName,
            surName: values.surName,
            phone: values.phone,
        }
        registerUser(payload).then((response: any) => {
            if (response && response.data?.message === 'success') {
                localStorage.setItem('verificationToken', response.data?.result.token)
                setShowVerification(true)
            } else {
                setErrorMessage(response.error?.data?.message)
            }
        })
    }

    const handleAgreeTerms = () => {
        setAcceptedTerms(true)
        setShowTerms(false)
    }

    const handleDeclineTerms = () => {
        setAcceptedTerms(false)
        setShowTerms(false)
    }

    const signupFormik = useFormik({
        initialValues: {
            firstName: '',
            phone: '',
            surName: ''
        },
        validationSchema: SignupSchema,
        validateOnMount: true,
        onSubmit: handleSignUp
    })

    const handleSuccess = () => {
        //     go to the next page
        setShowVerification(false);
        setUserVerified(true);
        navigate('/onboarding')
    }


    const buttonText = registeringUser ? 'Please wait ...' : 'Create your account';
    const formTitle = showVerification ? "Verify your phone number" : 'Create your account';

    const handleFieldUpdate = (value: string) => {
        setShowError(true)
        signupFormik.setFieldValue('phone', value)
    }

    return (
        <>
            <AuthWrapper>
                <div className={styles.form_container}>

                    {!userVerified && (
                        <div className="title_bar">
                            <h3>{formTitle}</h3>
                            {showVerification && (
                                <p>
                                    Please enter the <strong>5-digits</strong> code sent to you by SMS to finish with
                                    your
                                    account creation.
                                </p>
                            )}
                        </div>
                    )}

                    {!showVerification && !userVerified && (
                        <div className='form_inputs'>
                            <InputField
                                inputType="text"
                                value={signupFormik.values.firstName}
                                label="First name"
                                handleChange={(value: string) => signupFormik.setFieldValue('firstName', value)}
                            />
                            {showError && <small>{signupFormik.errors.firstName}</small>}
                            <InputField
                                inputType="text"
                                value={signupFormik.values.surName}
                                label="Surname"
                                handleChange={(value: string) => signupFormik.setFieldValue('surName', value)}
                            />
                            {showError && <small>{signupFormik.errors.surName}</small>}
                            <InputField
                                value={signupFormik.values.phone}
                                label="Phone number"
                                handleChange={handleFieldUpdate}
                                inputType="phone_number"
                            />
                            {showError && <small>{signupFormik.errors.phone}</small>}
                            <Checkbox
                                checked={acceptedTerms}
                                onChange={() => setAcceptedTerms(!acceptedTerms)}>
                                {`By clicking "Create your account" you automatically accept our `}
                                <span onClick={() => setShowTerms(true)} className="text-jc-primary hover:text-jc-secondary cursor-pointer">terms and conditions.</span>
                            </Checkbox>
                            {errorMessage && (
                                <Alert severity="warning">{errorMessage}</Alert>
                            )}
                            {registeringUser ?
                                <div
                                    className="bg-jc-primary py-3 text-center font-bold text-white rounded cursor-progress"
                                >
                                    Loading ...
                                </div>
                                :
                                <PrimaryButton
                                    buttonText={buttonText}
                                    onClicked={signupFormik.handleSubmit}
                                    disabled={!signupFormik.isValid || !acceptedTerms}
                                    type="submit"
                                />
                            }
                            <div className={styles.form_footer_text}>
                                <span>Have an account?</span>
                                <Link to="/login" className={styles.link}>{`Login`}</Link>
                            </div>
                        </div>
                    )}

                    {showVerification && (
                        <VerifyOtpForm
                            currentPhones={signupFormik.values.phone}
                            loading={registeringUser}
                            onSucceed={handleSuccess}
                        />
                    )}
                    {
                        userVerified && (
                            <div className="text-center">
                                <Lottie
                                    width={230}
                                    height={230}
                                    animationData={successAnimationData}
                                    loop={true}

                                />
                                <h4 className="font-bold text-gray-400 text-xl">Account created successfully!</h4>
                                <small className="text-gray-400">You are being redirected to your account setup
                                    page!</small>
                            </div>
                        )
                    }
                </div>
            </AuthWrapper>
            <Modal footer={null} title="JabuConnect Program Terms of Use" width={1000} open={showTerms}
                   onCancel={() => setShowTerms(false)}>
                <Terms/>
                <div className="flex space-x-5 justify-end items-center">
                    <Button onClick={handleDeclineTerms} danger>Decline</Button>
                    <Button onClick={handleAgreeTerms}>Accept</Button>
                </div>
            </Modal>
        </>
    )
}

export default SignUp
