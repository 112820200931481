import React, {useEffect, useState} from 'react';
import StepFormHead from "../features/onboarding/step-form-head";
import {OnboardingStep1FormProps} from "../../types/onboard-forms/step1";
import {Form, Input, Card, Select, Alert} from 'antd';
import {countries, countriesOptions} from "../../utils/constants/countries";
import {citiesOptions, genderOptions} from "../../utils/helpers";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import userService from "../../services/user.service";
import statics from "../../utils/constants/static-texts.json";
import PrimaryButton from "../widgets/primary-button";
import {useUpdateOnboardStep} from "../../hooks/parner_details_update";
import onboardingSteps from "../../utils/onboarding-steps.json";
import MainApi from "../../services/axiosInstance";
import SuburbField from "../features/suburbField";


function OnboardingStep1() {
    const {Item} = Form
    const [form] = Form.useForm()
    const formValues = Form.useWatch([], form);
    const {loading: loadingUser, userInfo} = useCurrentUser()
    // uI states
    const [loading, setLoading] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationType, setNotificationType] = useState<'success' | 'error'>('success')
    const [selectedCountry, setSelectedCountry] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [suburbsOptions, setSuburbsOptions] = useState<{ label: string, value: string }[]>([])
    const {increment} = useUpdateOnboardStep();
    const [loadingSuburbs, setLoadingSuburbs] = useState(true)

    const fetchSuburbs = async (cityName: string) => {
        const res = await MainApi.get(`/location/suburbs?cityName=${cityName}`)
        if (res && res.data.message === 'success') {
            const options = res.data.result.map((sub: { name: string }) => ({label: sub.name, value: sub.name}))
            setSuburbsOptions(options)
            setLoadingSuburbs(false)
        }
    }


    useEffect(() => {
        if (selectedCity) {
            fetchSuburbs(selectedCity).then(() => console.log('suburbs found'))
        }
    }, [selectedCity])

    useEffect(() => {
        if (userInfo) {
            form.setFieldsValue({
                firstName: userInfo.firstName,
                lastName: userInfo.surName,
                email: userInfo.email,
                suburb: userInfo.address?.suburb,
                erf: userInfo.address?.erf,
                street: userInfo.address?.street,
                idNumber: userInfo.idNumber,
                gender: userInfo.gender,
                country: userInfo.country,
                city: userInfo?.city
            })
            setSelectedCountry(userInfo?.country)
            setSelectedCity(userInfo?.city)
        }
    }, [userInfo])


    const handleSubmit = async (values: OnboardingStep1FormProps, goNext?: boolean) => {
        setNotificationMessage('')
        const payload = {
            country: values.country,
            city: selectedCity,
            firstName: values.firstName,
            surName: values.lastName,
            gender: values.gender,
            email: values.email,
            idNumber: values.idNumber,
            onboardingStatus: 'STEP_2',
            address: {
                suburb: values.suburb,
                erf: values.erf,
                street: values.street,
            }
        }
        //     save the date to server
        setLoading(true)
        try {
            await userService.updatePartnerDetails(payload).then((response) => {
                if (response && response.data.message === 'success') {
                    setNotificationMessage('Information saved successfully!')
                    setNotificationType('success')
                    setLoading(false)
                    if (goNext) {
                        increment(onboardingSteps.indexOf("Bank details"))
                    }
                } else {
                    setNotificationMessage(`We couldn't save your information now, please try again later.`)
                    setNotificationType('error')
                    setLoading(false)
                }
            })
        } catch (e: any) {
            setNotificationMessage(e.response.data?.message)
            setNotificationType('error')
            setLoading(false)
        }
    }

    const validateForm = (values: OnboardingStep1FormProps, goNext?: boolean) => {
        form.validateFields().then(
            () => {
                handleSubmit(values, goNext)
            },
            () => {
                console.log('error')
            },
        );
    }


    return (
        <div>
            <StepFormHead
                title="Tell us a bit more about yourself"
                subTitle="Here you have to put your personal information or the information of the MD of the company."
            />
            <Card>
                <Form
                    form={form}
                    layout="vertical"
                    name="validateOnly"
                    onFinish={values => validateForm(values)}
                    wrapperCol={{span: 24}}
                    labelCol={{span: 24}}
                    disabled={loadingUser}
                >
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                        <Item
                            name="firstName"
                            rules={[{required: true, message: 'Please enter your first name'}]}
                            label="First name"
                        >
                            <Input size="large" placeholder="Your first name"/>
                        </Item>
                        <Item
                            rules={[{required: true, message: 'Please enter your last name'}]}
                            label="Last name"
                            name="lastName"
                        >
                            <Input size="large" placeholder="Your last name"/>
                        </Item>
                        <Item
                            rules={[{required: true, message: 'Gender is required!'}]}
                            label="Gender"
                            name="gender"
                        >
                            <Select
                                showSearch
                                size="large"
                                options={genderOptions}
                            />
                        </Item>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <Item rules={[{required: true, message: 'This is required.'}]} label="ID or passport number"
                              name="idNumber">
                            <Input size="large" placeholder="e.g: 000000"/>
                        </Item>
                        <Item
                            rules={[{required: true, message: 'This should be a valid email.'}]}
                            label="Email address"
                            name="email"
                        >
                            <Input size="large" placeholder="e.g: you@email.com"/>
                        </Item>
                    </div>
                    <Item className="col-span-3">
                        <h1 className="text-xl font-bold">Personal address</h1>
                        <hr/>
                    </Item>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <Item
                            rules={[{required: true, message: 'Country is required!'}]}
                            label="Country"
                            name="country"
                        >
                            <Select
                                showSearch
                                onChange={(value) => setSelectedCountry(value)}
                                placeholder="e.g: Namibia"
                                size="large"
                                options={countriesOptions}
                            />
                        </Item>
                        <Item label="City" required name="city"
                              rules={[{required: true, message: 'City is required.'}]}>
                            <Select
                                disabled={selectedCountry === ''}
                                onChange={value => (form.setFieldValue('city', value), (setSelectedCity(value)))}
                                options={citiesOptions(selectedCountry === countries.Zambia ? statics.zambia_cities : selectedCountry === countries.Namibia ? statics.namibia_cities : selectedCountry === countries.South_Africa ? statics.south_africa_cities : [])}
                                placeholder="e.g: Windhoek" size="large"/>
                        </Item>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                        <SuburbField
                            city={selectedCity} onSuburbChange={(value: string) => form.setFieldValue('suburb', value)}
                        />
                        <Item
                            label="ERF/Plot number"
                            name="erf"
                        >
                            <Input size="large" placeholder="e.g: 2300"/>
                        </Item>
                        <Item
                            rules={[{required: true, message: 'Street is required'}]}
                            label="Street"
                            name="street"
                        >
                            <Input size="large" placeholder="e.g: Bismarck st"/>
                        </Item>
                    </div>
                    <Item className="col-span-3">
                        <hr/>
                        {notificationMessage && (
                            <Alert message={notificationMessage} type={notificationType} showIcon/>
                        )}
                    </Item>
                    <Item className="col-span-3">
                        <div className="footer_buttons_container">
                            <div className="button_container">
                                <PrimaryButton
                                    disabled={false}
                                    buttonText={loading ? 'Saving ...' : 'Next step'}
                                    onClicked={() => validateForm(form.getFieldsValue(), true)}
                                />
                            </div>
                        </div>
                    </Item>
                </Form>
            </Card>

        </div>
    );
}

export default OnboardingStep1;