import React from 'react';
import {parseArrayToString} from "../../utils/helpers";

interface Props {
    handleSubmission: any
    handleInputs: any
}

const OTPInputField: React.FC<Props> = ({handleSubmission, handleInputs}): JSX.Element => {
    const [otp, setOtp] = React.useState<string[]>(new Array(5).fill(""))
    const [activeField, setActiveField] = React.useState<number>(0)
    const inputRef = React.useRef<HTMLInputElement>(null)
    let currentOTPIndex = 0;

    const handleChange = ({target}: React.ChangeEvent<HTMLInputElement>): void => {
        const {value} = target;
        const newOTP: string[] = [...otp]
        newOTP[currentOTPIndex] = value.substring(value.length - 1)
        if (!value) setActiveField(currentOTPIndex - 1)
        else setActiveField(currentOTPIndex + 1)
        handleInputs(parseArrayToString(newOTP))
        setOtp(newOTP)
    }

    const handleOnKeyDown = ({key}: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        currentOTPIndex = index
        if (key === 'Enter') {
            if (parseArrayToString(otp).length === 5) {
                handleSubmission(otp)
            }
        }
    }

    React.useEffect(() => {
        inputRef.current?.focus();
    }, [activeField])
    return (
        <div className="flex space-x-2 items-center justify-center">
            {otp.map((_, index) => {
                return (
                    <React.Fragment key={index}>
                        <input type="number"
                               ref={activeField === index ? inputRef : null}
                               onChange={handleChange}
                               onKeyDown={(e) => handleOnKeyDown(e, index)}
                               value={otp[index]}
                               className="otp_input spin-button-none mb-10"
                        />
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default OTPInputField;