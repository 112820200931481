import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/base.scss';
import './styles/components.scss';
import App from './App';

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const root = createRoot(container);


root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)