import React, {FC} from 'react';
import { ToastContainer } from 'react-toastify';

interface Props {
    title: string
    subTitle?: string
}

const StepFormHead: FC<Props> = ({title, subTitle}: Props) => {
    return (
        <div className="steps_form_head">
            <ToastContainer position="top-center"/>
            <h4>{title}</h4>
            <p>{subTitle}</p>
        </div>
    )
}

export default StepFormHead;