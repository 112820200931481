import React, {useState} from 'react';
import {Button, Card, Form, Input, FloatButton, Tooltip} from "antd";
import {MdClose, MdDone} from "react-icons/md";
import TextArea from "antd/es/input/TextArea";
import {AiFillMessage} from "react-icons/ai";
import ContactService from "../../services/contact.service";


const ContactForm = () => {
    const [showContactForm, setShowContactForm] = useState(false)
    const [validForm, setValidForm] = useState(false)
    const [form] = Form.useForm()
    const {Item} = Form
    const formValues = Form.useWatch([], form);
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)

    const showForm = () => {
        setShowContactForm(!showContactForm)
    }

    const handleSendMail = async (values: { message: string, email: string, name: string }) => {
        const payload = {
            email: values.email,
            fullName: values.name,
            message: values.message
        }
        setLoading(true)
        try {
            await ContactService.contactUs(payload).then((res) => {
                if (res && res.data.message === 'success') {
                    setSent(true)
                }
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }

    };

    React.useEffect(() => {
        form.validateFields(['email', 'name', 'message']).then(
            () => {
                setValidForm(false);
            },
            () => {
                setValidForm(true);
            },
        );
    }, [formValues]);


    return (
        <div
            className="bottom-0 transition-all absolute right-0 mb-12 mr-4 space-y-2 flex flex-col justify-end items-end">
            {showContactForm && (
                <Card className="shadow-2xl transition-all duration-200" title="Contact us" bordered={false}
                      style={{width: 320}}
                      extra={<MdClose className="text-jc-primary cursor-pointer" onClick={showForm}></MdClose>}>
                    {sent ? (
                        <div className="w-full flex justify-center items-center flex-col py-5">
                            <MdDone className="w-20 h-20 text-center text-green-400"/>
                            <p className="text-center">Your message is well received, we will come back to your
                                shortly!</p>
                            <Button onClick={showForm} block type="primary">Ok</Button>
                        </div>

                    ) : <Form
                        name="validateOnly"
                        layout="vertical"
                        form={form}
                        onFinish={values => handleSendMail(values)}
                    >
                        <Item validateStatus="" label="Full name"
                              rules={[{required: true, message: 'Full name is required.'}]}
                              name="name">
                            <Input size="large" placeholder="e.g: John Kelvin"/>
                        </Item>
                        <Item validateStatus="" label="Email"
                              rules={[{required: true, message: 'Email is required.'}]}
                              name="email">
                            <Input type="email" size="large" placeholder="e.g: you@example.com"/>
                        </Item>
                        <Item validateStatus="" label="Message"
                              rules={[{required: true, message: 'message is required.'}]}
                              name="message">
                            <TextArea size="large" placeholder="."/>
                        </Item>
                        <Item>
                            <Button loading={loading} disabled={validForm} type="primary" block htmlType="submit"
                                    size="large"
                                    placeholder="."> Submit </Button>
                        </Item>
                    </Form>}

                </Card>
            )}
            <Tooltip title="Contact us">
                <FloatButton
                    onClick={showForm}
                    icon={<AiFillMessage/>}
                />
            </Tooltip>
        </div>
    )

}

export default ContactForm;