import React from "react";

const InclinedDecoration:React.FC = () => {
    return (
        <div className="inclined_decoration">
            <div className="incliner_top"></div>
            <div className="incliner_container">
                <div className="left_incliner_decoration">
                    <div></div>
                    <div></div>
                </div>
                <div className="right_incliner_decoration">
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default InclinedDecoration;