import React from "react";
import staticTexts from '../../utils/constants/static-texts.json';
import ListTile from "./listTile";
import images from '../../utils/constants/assets';
import styles from '../../pages/authentication/authentication.module.scss';
import Copyright from "./copyright";

const {signup_intro} = staticTexts;

const AuthLeftSide: React.FC = () => {
    return (
        <div className={styles.left_container}>
            <div className={styles.become_driver_container}>
                <img src={images.becomeJabuDriver} alt="become JabuConnect partner" />
            </div>
            <div>
                {signup_intro.map((item: { title: string, description: string }) => (
                    <ListTile title={item.title} description={item.description} key={item.title}/>
                ))}
            </div>
            <Copyright />
        </div>
    )
}

export default AuthLeftSide;