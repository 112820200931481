import React, {FC, useEffect, useState} from "react";
import StepsNavigation from "../../Components/features/onboarding/steps-navigation";
import styles from "./onboarding-driver.module.scss";
import {MdLogout} from "react-icons/md";
import FormsWrapper from "../../Components/forms/onboarding-steps-forms-wrapper";
import ContactForm from "../../Components/features/contact-form";
import {logoutUser} from "../../utils/helpers";
import {useAppSelector} from "../../redux/user-redux";
import {selectOnboardingState} from "../../redux/onboarding/onboarding-slice";
import {Alert} from "antd";
import OnboardingIntro from "../../Components/onboarding-intro";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {useUpdateOnboardStep} from "../../hooks/parner_details_update";

const Onboarding: FC = () => {
    const {errorMessage} = useAppSelector(selectOnboardingState);
    const [doneIntro, setDone] = useState<null | string>(null)

    useEffect(() => {
        const checked = localStorage.getItem('done_intro')
        if (checked) {
            setDone(checked)
        }
    }, [])

    const {loading: isLoading, userInfo} = useCurrentUser();
    const {increment} = useUpdateOnboardStep();


    const LoadingPlaceHolders: React.FC = () => {
        return (
            <div>
                <h3 className="animate-bounce">Please wait ...</h3>
            </div>
        );
    };

    const Wrapper = () => {
        return (
            <>
                {isLoading && (
                    <LoadingPlaceHolders/>
                )}
                {userInfo && !userInfo.country && !doneIntro && (
                    <OnboardingIntro/>
                )}
                {userInfo && (userInfo.country || doneIntro) && (
                    <>
                        <div className={styles.steps_nav_container}>
                            <StepsNavigation/>
                        </div>
                        <div className={styles.step_form_wrapper}>
                            <FormsWrapper/>
                            {errorMessage && (
                                <Alert closable message={errorMessage} type="error"/>
                            )}
                        </div>
                        <ContactForm />
                    </>
                )}
            </>
        )
    };


    useEffect(() => {
        if (userInfo && userInfo.onboardingStatus === 'PRE_ONBOARDING') {
            increment(0)
        }
    }, [userInfo])

    return (
        <>
            <div className={styles.onboarding_header}>
                <div className="mt-2">
                    <h4>Partner onboarding</h4>
                </div>
                <div onClick={logoutUser} className={styles.close_container}>
                    <MdLogout/>
                    <span>logout</span>
                </div>
            </div>
            <div className={styles.onboard_main_wrapper}>
                <Wrapper/>
            </div>
        </>
    );
};

export default Onboarding;
