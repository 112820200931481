import React, { FC } from "react";
import PrimaryButton from "./primary-button";
import { FaArrowRight } from "react-icons/fa";
import { useUpdateOnboardStep } from "../../hooks/parner_details_update";
import onboardingSteps from "../../utils/onboarding-steps.json";
import { saveStep } from "../../utils/helpers";

interface NextStepProps {
  step: string;
  label?: string;
  disabled?: boolean;
}

const OnboardingNext: FC<NextStepProps> = ({ step, label, disabled }) => {
  const { increment } = useUpdateOnboardStep();

  const handleNavigateSteps = () => {
    if (step === "Bank details") {
      saveStep("STEP_2").then(() => increment(onboardingSteps.indexOf(step)));
    }
    if (step === "Documents upload") {
      saveStep("STEP_3").then(() => increment(onboardingSteps.indexOf(step)));
    }
    if (step === "Business details") {
      saveStep("STEP_4").then(() => increment(onboardingSteps.indexOf(step)));
    }
    if (step === "Add your drivers") {
      saveStep("STEP_5").then(() => increment(onboardingSteps.indexOf(step)));
    }
    if (step === "Add your vehicles") {
      saveStep("STEP_6").then(() => increment(onboardingSteps.indexOf(step)));
    }
    if (step === "Summary") {
      saveStep("STEP_7").then(() => increment(onboardingSteps.indexOf(step)));
    }
    increment(onboardingSteps.indexOf(step));
  };

  return (
    <>
      <PrimaryButton
        buttonText={label ? label : "Next"}
        onClicked={() => handleNavigateSteps()}
        disabled={disabled || false}
        type="button"
      />
    </>
  );
};

export default OnboardingNext;
