import React, {useEffect, useState} from 'react';
import styles from '../../../pages/homepage/homepage.module.scss';
import {Skeleton} from "@mui/material";
import {HomePageStats, ShipmentsStats} from "../../../types/stats";
import MainApi from "../../../services/axiosInstance";
import ValueBox from "../../widgets/value-box";
import {Col, Row} from "antd";

const SmallBox = ({label, value, currency}: { label: string, value: number, currency?: string }) => {
    return (
        <div className="small_box">
            <h3>{label}</h3>
            <span>{`${currency} ${value}`}</span>
        </div>
    )
}


const StatsSection: React.FC = () => {
    const [stats, setStats] = useState<ShipmentsStats>()
    const [loading, setLoading] = useState<boolean>(false)

    const fetchStats = async () => {
        setLoading(true)
        await MainApi.get('/shipments/statistics').then((response) => {
            if (response && response.data?.result) {
                setStats(response.data.result)
            }
        })
    }

    useEffect(() => {
        fetchStats().then(() => setLoading(false))
    }, [])

    return (
        <>
            {!loading && stats ? (<>
                <Row gutter={[16, 16]}>
                    <Col xs={{span: 24}} lg={{span: 12}}>
                        <ValueBox title="Pending" value={stats?.PENDING}/>
                    </Col>
                    <Col xs={{span: 24}} lg={{span: 12}}>
                        <ValueBox title="Completed" value={stats?.COMPLETED}/>
                    </Col>
                    <Col xs={{span: 24}} lg={{span: 12}}>
                        <ValueBox title="Cancelled" value={stats?.CANCELLED}/>
                    </Col>
                    <Col xs={{span: 24}} lg={{span: 12}}>
                        <ValueBox title="Accepted" value={stats?.ACCEPTED}/>
                    </Col>
                </Row>
            </>) : <div className="grid grid-cols-2 md:grid-cols-2 gap-10">
                <Skeleton width={200} height={60}/>
                <Skeleton width={200} height={60}/>
                <Skeleton width={200} height={60}/>
                <Skeleton width={200} height={60}/>
            </div>}
        </>
    )
}
export default StatsSection;