import {createApi} from "@reduxjs/toolkit/query/react";
import ProtectedServicesConfig from "../../config/protectedServices";
import URLS from "../../utils/constants/urls";
import {BaseQueryResult} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const UserApi = createApi({
    reducerPath: 'UserApi',
    baseQuery: ProtectedServicesConfig,
    tagTypes: ['user'],
    endpoints: (builder) => ({
        getProfile: builder.query<any, void>({
            query: () => URLS.profile,
            transformResponse: (response: BaseQueryResult<any>) => {
                if (response && response?.result) {
                    return response.result;
                }
            },
            providesTags: ['user']
        }),
        updateUserDetails: builder.mutation<any, any>({
            query: (payload) => ({
                url: URLS.updateUser,
                method: 'PATCH',
                body: payload
            }),
            invalidatesTags: ['user']
        })
    })
})

export const {useGetProfileQuery, useUpdateUserDetailsMutation, useLazyGetProfileQuery} = UserApi;