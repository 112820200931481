import {useEffect, useState} from "react";
import Urls from "../utils/constants/urls";
import MainApi from "../services/axiosInstance";
import {logoutUser} from "../utils/helpers";


export const useCurrentUser = () => {
    const [userInfo, setUserInfo] = useState<any>(undefined);
    const [loading, setLoading] = useState(true)

    const getProfileData = async () => {
        try {
            await MainApi.get(Urls.profile).then((response) => {
                if (response && response.data.message === 'success') {
                    setUserInfo(response.data.result)
                } else {
                    logoutUser()
                }
            })
        } catch (e: any) {
            if (e?.response?.data?.responseCode === 'UNAUTHORIZED') {
                logoutUser()
            }
        }
    }


    useEffect(() => {
        if (!userInfo) {
            getProfileData().then(() => setLoading(false))
        }
    }, [])

    return {loading, userInfo};
}