import React, {FC, useEffect, useState} from 'react';
import DashboardWrapper from "../../Components/widgets/dashboard_wrapper";
import {Card} from 'antd';
import ValueBox from "../../Components/widgets/value-box";
import {BarChart} from "../../Components/widgets/bar-chart";
import VehiclesView from "../../Components/features/vehicles-view";
import GlobalFilter from "../../Components/widgets/date-range-picker";
import {Skeleton} from "antd";
import {UseVehicles} from "../../hooks/useVehicles";
import MainApi from "../../services/axiosInstance";
import Urls from "../../utils/constants/urls";
import {MdFactCheck} from "react-icons/md";


interface ShipmentReportProps {
    activeShipments: number
    monthlyShipments: number
    totalShipments: number
    shipments: { totalRevenue: number, date: string }[]
}


const Shipment: FC = () => {
    const {loadingVehicles, vehicles} = UseVehicles()
    const [shipmentReport, setShipmentReport] = useState<any>()
    const [loadingShipmentReport, setLoadingShipmentReport] = useState(true)
    const [selectedId, setSelectedId] = useState<string | undefined>()
    const [persistedFromDate, setPersistedFromDate] = useState<string | undefined>()
    const [persistedToDate, setPersistedToDate] = useState<string | undefined>()

    const handleFetchShipmentReport = async (params?: string) => {
        try {
            await MainApi.get(`/shipments/reports` + (params ? params : '')).then((response: any) => {
                if (response && response?.data?.message === 'success') {
                    setShipmentReport(response.data.result)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    const handleFilter = (from?: string, to?: string, vehicleId?: string) => {
        setSelectedId(vehicleId)
        setLoadingShipmentReport(true)
        let params = ''
        if (from && to && params === '') {
            params = `?fromDate=${from}&toDate=${to}`
            setPersistedFromDate(from)
            setPersistedToDate(to)
        }
        if (vehicleId) {
            params.startsWith('?') ? params += `&vehicleId=${vehicleId}` : params += `?vehicleId=${vehicleId}`
        }
        handleFetchShipmentReport(params).then(() => setLoadingShipmentReport(false))
    }

    const resetFilterNoVehicle = () => {
        setLoadingShipmentReport(true)
        setPersistedFromDate(undefined)
        setPersistedToDate(undefined)
        handleFetchShipmentReport().then(() => setLoadingShipmentReport(false))
    }

    const resetFilterVehicle = () => {
        setLoadingShipmentReport(true)
        setSelectedId(undefined)
        handleFetchShipmentReport().then(() => setLoadingShipmentReport(false))
    }

    useEffect(() => {
        handleFetchShipmentReport().then(() => setLoadingShipmentReport(false))
    }, [])

    const getVehicle = (id: string) => {
        const found: any = vehicles.find((v: any) => v._id === id)
        if (found) {
            return found.plateNumber
        }
    }


    return (
        <DashboardWrapper>
            <div
                className="flex justify-between flex-col md:flex-row md:items-center gap-4 mb-4 bg-white py-3 px-6 md:px-14">
                <span className="page_section_title">Shipment report {selectedId &&
                    <span className="text-jc-secondary uppercase font-medium"> - {getVehicle(selectedId)}</span>}</span>
                <GlobalFilter handleResetFilter={() => resetFilterNoVehicle()}
                              handleFilter={(from, to) => handleFilter(from, to, selectedId)}/>
            </div>
            <div className="flex justify-start gap-4 flex-col md:flex-row md:px-14 px-6">
                <div className="md:w-3/12 w-full">
                    <Card
                        loading={loadingVehicles} bordered={false}
                        title="Your garage"
                        extra={selectedId && <div onClick={resetFilterVehicle}
                                                  className="text-jc-primary cursor-pointer hover:text-red-500">Clear
                            selection</div>}
                    >
                        {vehicles ?
                            <VehiclesView
                                selectedVehicleId={selectedId}
                                onVehicleChange={(vehicleId: string) => handleFilter(persistedFromDate, persistedToDate, vehicleId)}
                                VehiclesList={vehicles}
                            /> :
                            <div className="flex justify-between items-center">
                                <Skeleton active avatar/>
                                <Skeleton active/>
                            </div>}
                    </Card>
                </div>
                <div className="md:w-9/12 w-full">
                    {loadingShipmentReport && (
                        <div className="flex justify-between items-center">
                            <Skeleton active avatar/>
                            <Skeleton active/>
                        </div>
                    )}
                    <Card bordered={false}>
                        {shipmentReport && <div className="stats_boxes">
                            <ValueBox title="Total shipments" value={`${shipmentReport.totalShipments}`}/>
                            <ValueBox title="Completed shipments"
                                      value={`${shipmentReport.completedShipments}`}/>
                            <ValueBox title="Cancelled shipments"
                                      value={`${shipmentReport.cancelledShipments}`}/>
                            <ValueBox title="Active shipments"
                                      value={`${shipmentReport.activeShipments}`}/>
                        </div>}
                    </Card>
                    {!loadingShipmentReport && shipmentReport && (<Card bordered={false} className="my-3">
                        <>
                            <div className="title_bar_pages">
                                {!loadingShipmentReport && <p className="section_title">{`Shipment stats`}</p>}
                            </div>
                            {shipmentReport && shipmentReport.shipments && shipmentReport.shipments.length > 0 ? (
                                <BarChart valueKey="totalShipments" labelTitle="Shipments"
                                          seriesData={shipmentReport.shipments}/>
                            ) : <div className="text-gray-400 text-center">
                                {!loadingShipmentReport &&
                                    <div><MdFactCheck size="100" className="mx-auto my-4"/> <h3>You have no shipments
                                        done.</h3>
                                        {selectedId || persistedFromDate && (<div onClick={resetFilterVehicle}
                                                                                  className="text-jc-primary cursor-pointer hover:text-red-500">Clear
                                            filter
                                        </div>)}
                                    </div>
                                }
                            </div>}
                        </>
                    </Card>)}
                </div>
            </div>

        </DashboardWrapper>
    )
}

export default Shipment;