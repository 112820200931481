import {useEffect, useState} from 'react'
import vehicleService from "../services/vehicle.service";


export const UseVehicles = () => {
    const [vehicles, setVehicles] = useState([])
    const [loadingVehicles, setLoadingVehicles] = useState(true)


    const fetchVehiclesList = async () => {
        await vehicleService.getAllVehicles().then((res) => {
            if (res.data && res.data.message === 'success') {
                setVehicles(res.data?.result)
            }
        })
    }

    useEffect(() => {
        fetchVehiclesList().then(() => setLoadingVehicles(false))
    }, [])

    const refetchVehicles = () => {
        fetchVehiclesList().then(() => setLoadingVehicles(false))
    }

    return {loadingVehicles, vehicles, refetchVehicles}
}