import React, {FC} from 'react';
import DashboardWrapper from "../../Components/widgets/dashboard_wrapper";

const Settlement:FC = () => {
    return (
        <DashboardWrapper>
            <div>
                <h5>coming soon </h5>
            </div>
        </DashboardWrapper>
    )
}

export default Settlement;