import React, {FC, useEffect, useState} from 'react'
import {Alert, Card, Form, Input, Select} from 'antd'
import staticData from "../../utils/constants/static-texts.json";
import {accountTypeOptions} from "../../utils/helpers";
import userService from "../../services/user.service";
import onboardingSteps from "../../utils/onboarding-steps.json";


interface UserProps {
    userDetails: any
}

const BankDetails: FC<UserProps> = ({userDetails}) => {
    const [form] = Form.useForm()
    const {Item} = Form
    const [loading, setLoading] = useState(false)

    const [notification, setNotification] = useState<{ message: string, type: 'success' | 'error' }>({
        message: '',
        type: 'success'
    })

    useEffect(() => {
        if (userDetails) {
            form.setFieldsValue({
                branchName: userDetails?.bankDetails?.branchName,
                branchCode: userDetails?.bankDetails?.branchCode,
                bankName: userDetails?.bankDetails?.bankName,
                accountNumber: userDetails?.bankDetails?.accountNumber,
                accountType: userDetails?.bankDetails?.accountType
            })
        }
    }, [userDetails])


    const handleSubmit = async (values: any) => {
        setLoading(true)
        const payload = {
            branchName: values.branchName,
            branchCode: values.branchCode,
            bankName: values.bankName,
            accountNumber: values.accountNumber,
            accountType: values.accountType
        }
        setNotification({message: '', type: 'success'})

        try {
            await userService.updatePartnerDetails({
                bankDetails: payload
            }).then((response) => {
                if (response && response.data.message === 'success') {
                    setNotification({message: 'Bank details are saved successfully!', type: 'success'})
                } else {
                    setNotification({
                        message: `We are not able to save your bank details now, please try again`,
                        type: "error"
                    })
                }
            })
        } catch (e: any) {
            setNotification({message: e.response.data?.message, type: "error"})
        } finally {
            setLoading(false)
        }
    }


    return (
        <Card>
            <Form
                form={form}
                layout="vertical"
                wrapperCol={{span: 24}}
                labelCol={{span: 24}}
                onFinish={handleSubmit}
                disabled={!userDetails}
            >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <Item
                        label="Bank name"
                        name="bankName"
                        rules={[{required: true, message: 'Bank name is required!'}]}
                    >
                        <Select
                            showSearch
                            placeholder="Select your Bank"
                            size="large"
                            options={userDetails?.country && userDetails?.country === 'Zambia' ? staticData.zambiaBanks : userDetails?.country === 'South Africa' ? staticData.southAfricaBanks : staticData.namibiaBanks}
                        />
                    </Item>
                    <Item
                        label="Branch name"
                        name="branchName"
                        rules={[{required: true, message: 'Branch name is required!'}]}
                    >
                        <Input size="large" placeholder={"e.g: Capricorn Branch"}/>
                    </Item>
                    <Item
                        label="Branch code"
                        name="branchCode"
                        rules={[{required: true, message: 'Branch code is required!'}]}
                    >
                        <Input size="large" placeholder={"e.g: 48600"}/>
                    </Item>
                    <Item
                        label="Account number"
                        name="accountNumber"
                        rules={[{required: true, message: 'Account number is required!'}]}
                    >
                        <Input size="large" placeholder={"e.g: 8003758105"}/>
                    </Item>
                    <Item
                        label="Account type"
                        name="accountType"
                        rules={[{required: true, message: 'Account type is required!'}]}
                    >
                        <Select
                            showSearch
                            placeholder="eg: Checking"
                            size="large"
                            options={accountTypeOptions}
                        />
                    </Item>
                </div>
                <Item className="col-span-2">
                    <hr/>
                    {notification.message && (
                        <Alert message={notification.message} type={notification.type} showIcon/>
                    )}
                </Item>
                <div className="flex justify-end items-center">
                    <div className="md:w-2/12">
                        <button
                            type="submit"
                            className="primary_button"
                            disabled={loading}
                        >
                            {loading ? 'Please wait ...' : 'Save changes'}
                        </button>
                    </div>
                </div>

            </Form>
        </Card>
    )
}

export default BankDetails;