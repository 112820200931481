import {createApi} from "@reduxjs/toolkit/query/react";
import ProtectedServicesConfig from "../../config/protectedServices";
import URLS from "../../utils/constants/urls";
import {BaseQueryResult} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const EarningsService = createApi({
    reducerPath: 'EarningsService',
    baseQuery: ProtectedServicesConfig,
    tagTypes: ['earningsReport'],
    endpoints: (builder) => ({
        fetchEarningsReport: builder.query<any, any>({
            query: (params?: string) => ({
                url: URLS.earnings_report + params
            }),
            providesTags: ['earningsReport'],
            transformResponse: (response: BaseQueryResult<any>) => {
                return response.result;
            },
        }),
    })
})

export const {useLazyFetchEarningsReportQuery} = EarningsService