import * as yup from 'yup';

const VehicleSchema = yup.object().shape({
    type: yup.string().required("Vehicle type is required field"),
    brandName: yup.string().required('Vehicle brand name is required field'),
    yearModel: yup.number().required("Year model is required field").max(2025, 'Year model should not be above current year'),
    colour: yup.string().required('Color is required field'),
    VINNumber: yup.string().required("VIN Number is required field"),
    kilometers: yup.number().required("Kilometer is required field").min(1),
    weight: yup.number().required("Vehicle's weight is required field").min(1),
    plateNumber: yup.string().required("Plate number is required field"),
    pictureFront: yup.string().required("Your Vehicle's front picture is required field"),
    pictureBack: yup.string().required("Your Vehicle's Back picture is required field"),
    pictureSide: yup.string().required("Your Vehicle's Side picture is required field"),
    driverId: yup.string().required('Driver is required field')
})
export default VehicleSchema;