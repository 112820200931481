import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

interface VehicleProps {
    selected_Vehicle?: {
        id: string
        brand_name: string
    }
}

const defaultState: VehicleProps = {
    selected_Vehicle: undefined
}

const VehiclesSlice = createSlice({
    name: 'Vehicles',
    initialState: defaultState,
    reducers: {
        update(state, action: PayloadAction<Record<string, any>>) {
            return {
                ...state,
                ...action.payload
            }
        },
    }
})

export  const selectVehiclesState = (state:RootState) => state.Vehicles

export const {update} = VehiclesSlice.actions
export default VehiclesSlice.reducer