import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SignUp from "../../../pages/authentication/signup";
import Login from "../../../pages/authentication/login";
import Onboarding from "../../../pages/onboarding";
import Review from "../../../pages/under-review/review";
import Policy from "../../../pages/policy";

export default function PublicRoutes () {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<SignUp />} path="/" />
                <Route element={<SignUp />} path="/signup" />
                <Route element={<Login />} path="/login" />
                <Route element={<Policy />} path="/policy" />
                <Route element={<Onboarding />} path="/onboarding" />
                <Route element={<Review/>} path="/onboarding-home"/>
                <Route element={<Login />} path="*" />
            </Routes>
        </BrowserRouter>
    )
}