import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {updatePartnerDetails} from "./onboard-thunk";

interface Props {
    updating: boolean
    success: boolean
    formValues: any
    currentStepIndex: number
    doneSteps: number[]
    errorMessage: string
}

const defaultState:Props = {
    updating: false,
    success: false,
    formValues: undefined,
    currentStepIndex: 123,
    doneSteps: [0],
    errorMessage: ''
}

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState: defaultState,
    reducers: {
        updateNavState(state, action: PayloadAction<Record<string, any>>) {
            return {
                ...state,
                ...action.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updatePartnerDetails.pending, (state) => {
            state.updating = true
        })
            .addCase(updatePartnerDetails.fulfilled, (state) => {
                state.success = true
                state.updating = false
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                updateNavState({currentStepIndex: 4});
                console.log('go to next step')
            })
            .addCase(updatePartnerDetails.rejected, (state) => {
                state.updating  = false
                state.success = false
            })
    }
})
export const selectOnboardingState = (state: RootState) => state.onboarding;
export const { updateNavState }= onboardingSlice.actions;
export  default onboardingSlice.reducer;