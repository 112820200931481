import React, {FC, useState} from 'react';
import {DatePicker, Button} from 'antd'
import {globalDateFormat} from "../../utils/helpers";
import moment from "moment/moment";

const {RangePicker} = DatePicker

interface GlobalFilterProps {
    handleFilter: (fromDate: string | undefined, toDate: string | undefined) => void
    handleResetFilter: () => void
}

const GlobalFilter: FC<GlobalFilterProps> = ({handleFilter, handleResetFilter}) => {
    const [fromDate, setFromDate] = useState<string>()
    const [toDate, setToDate] = useState<string>()

    const handleDateUpdate = (e: { $d: moment.MomentInput; }[]) => {
        setToDate('')
        setFromDate('')
        if (e !== null) {
            setFromDate(moment(e[0]?.$d).format('YYYY-MM-DD'))
            setToDate(moment(e[1]?.$d).format('YYYY-MM-DD'))
        } else {
            handleResetFilter()
        }
    }

    function disabledDate(current: any) {
        // Disable all dates that are after today
        return current && current > moment().endOf('day');
    }

    return (
        <div className="flex items-center gap-4 justify-between">
            <RangePicker
                format={globalDateFormat}
                onChange={(e: any) => handleDateUpdate(e)}
                disabledDate={disabledDate}
                size="large"
                style={{minWidth: "250px"}}
            />
            <Button onClick={() => handleFilter(fromDate, toDate)} type="primary" size="large"
                    className="primary_button">Filter</Button>
        </div>
    )
}

export default GlobalFilter;