import {createApi} from "@reduxjs/toolkit/query/react";
import ProtectedServicesConfig from "../../config/protectedServices";
import URLS from "../../utils/constants/urls";
import {BaseQueryResult} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const ReportsApi = createApi({
    reducerPath: 'ReportsApi',
    baseQuery: ProtectedServicesConfig,
    tagTypes: ['earnings', 'reports'],
    endpoints: (builder) => ({
        getEarningsReports: builder.query<any, { fromDate: Date; toDate: Date }>({
            query: (arg) => {
                const {fromDate, toDate} = arg;
                console.log('arg: ', arg);
                return {
                    url: `${URLS.earnings_report}/`,
                    params: {fromDate, toDate},
                };
            },
            transformResponse: (response:BaseQueryResult<any>) => {
                return response.result;
            },
        }),
        getShipmentReports: builder.query<any, { fromDate: Date; toDate: Date }>({
            query: (arg) => {
                const {fromDate, toDate} = arg;
                return {
                    url: `${URLS.shipment_report}/`,
                    params: {fromDate, toDate},
                };
            },
            transformResponse: (response:BaseQueryResult<any>) => {
                return response.result;
            },
        })
    })
})

export const {useGetEarningsReportsQuery, useGetShipmentReportsQuery} = ReportsApi;