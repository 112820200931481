import {createApi} from "@reduxjs/toolkit/query/react";
import URLS from '../../utils/constants/urls';
import VerificationApiConfig from "../../config/verificationApiConfig";

export const VerificationApi = createApi({
    reducerPath: 'VerificationApi',
    baseQuery: VerificationApiConfig,
    tagTypes: ['verify'],
    endpoints: (builder) => ({
        verifyOtp: builder.mutation<{ code: string }, any>({
            query: (payload) => ({
                url: URLS.verify_otp,
                method: 'POST',
                body: payload
            })
        }),
    })
})
export const {useVerifyOtpMutation} = VerificationApi;