import React, {FC, useEffect} from 'react';
import styles from "../../pages/earnings/earnings.module.scss";
import VehicleRow from "../widgets/vehicle-row";
import {useAppDispatch} from "../../redux/user-redux";
import {update} from "../../redux/vehicles/vehicles-slice";
import {VehicleRowProps} from "../../types/global-types";


interface VehiclesViewProps {
    VehiclesList: VehicleRowProps[]
    onVehicleChange: (vehicleId: string) => void
    selectedVehicleId: string | undefined
}

const VehiclesView: FC<VehiclesViewProps> = ({VehiclesList, onVehicleChange, selectedVehicleId}) => {

    if (VehiclesList && VehiclesList.length > 0) {
        const dispatch = useAppDispatch();
        const selected_Vehicle = {
            id: VehiclesList[0]?._id,
            plateNumber: VehiclesList[0]?.plateNumber
        }
        useEffect(() => {
            dispatch(update({selected_Vehicle}))
        }, [])
        return (
            <div className={styles.Vehicles_container}>
                {VehiclesList?.map((i: VehicleRowProps, index: number) => (
                    <VehicleRow
                        key={index}
                        selectedVehicleId={selectedVehicleId}
                        onSelectVehicle={() => onVehicleChange(i._id)}
                        pictures={i.pictures}
                        yearModel={i.yearModel}
                        weight={i.weight}
                        plateNumber={i.plateNumber}
                        brandName={i.brandName}
                        _id={i._id}
                    />
                ))}
            </div>
        )
    }
    return (
        <div>
            no data
        </div>
    )
}

export default VehiclesView;