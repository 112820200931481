import React, {FC, useState} from 'react';
import {VehicleRowProps} from "../../types/global-types";


const VehicleRow: FC<VehicleRowProps> = ({
                                             pictures,
                                             brandName,
                                             plateNumber,
                                             _id,
                                             selectedVehicleId,
                                             onSelectVehicle,
                                         }: VehicleRowProps) => {

    return (
        <div className={`Vehicle_row ${selectedVehicleId === _id ? 'active_Vehicle' : ''}`}
             onClick={() => onSelectVehicle(_id)}>
            <img src={pictures?.front} alt={brandName}/>
            <div>
                <h4>#{plateNumber}</h4>
                <p>Brand: {brandName}</p>
            </div>
        </div>
    )
}

export default VehicleRow;