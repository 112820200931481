import React from 'react';
import 'react-phone-number-input/style.css';
import {TextInputProps} from "../../types/text-input";
import PhoneInput from "react-phone-number-input";
import 'react-phone-input-2/lib/style.css';

const InputField: React.FC<TextInputProps> = ({label, value, placeHolder, optional, handleChange, inputType, disabled}: TextInputProps) => {

    return (
        <div>
            <label htmlFor="input">{label} <span className="text-red-500">*</span></label>
            {optional && (
                <span>
                    Optional
                </span>
            )}
            {inputType === 'phone_number' ? (
                // <PhoneInput
                //     country={'na'}
                //     onChange={handleChange}
                //     value={value}
                //     preferredCountries={['na', 'zm', 'za']}
                //     containerStyle={{ border: "none" }}
                //     enableLongNumbers={12}
                //     inputStyle={{
                //         width: "100%",
                //         height: "44px",
                //         paddingLeft: "60px",
                //         fontSize: "16px",
                //     }}
                //     buttonStyle={{ width: "54px", height: 44 }}
                //
                // />
                <PhoneInput
                    placeholder={"Enter phone number"}
                    value={value}
                    countryCallingCodeEditable={false}
                    defaultCountry={"NA"}
                    onChange={handleChange}
                    international
                    limitMaxLength
                />
            ) : <input
                type={inputType || 'text' }
                placeholder={placeHolder}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                className={inputType}
                required={!optional}
                disabled={disabled}
            />}
        </div>
    )
}


export default InputField;