import React, {FC} from 'react';
import DashboardWrapper from "../../Components/widgets/dashboard_wrapper";
import {Tabs} from 'antd'
import PersonalDetails from "../../Components/user-settings/personal-details";
import BankDetails from "../../Components/user-settings/BankDetails";
import BusinessDetails from "../../Components/user-settings/BusinessDetails";
import DocumentsDetails from "../../Components/user-settings/DocumentsDetails";
import {useCurrentUser} from "../../hooks/useCurrentUser";

const Settings: FC = () => {

    const {userInfo} = useCurrentUser()

    console.log(userInfo)

    return (
        <DashboardWrapper>
            <div
                className="flex justify-between flex-col md:flex-row md:items-center gap-4 mb-4 bg-white py-3 px-6 md:px-14">
                <span className="page_section_title">User settings</span>
            </div>
            <section className="md:px-14 px-6">
                <Tabs defaultActiveKey="1" centered>
                    <Tabs.TabPane key="1" tab="Personal details">
                        <PersonalDetails userDetails={userInfo}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="2" tab="Bank details">
                        <BankDetails userDetails={userInfo}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="3" tab="Business details">
                        <BusinessDetails userDetails={userInfo}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="4" tab="Documents">
                        <DocumentsDetails userDetails={userInfo}/>
                    </Tabs.TabPane>
                </Tabs>

            </section>
        </DashboardWrapper>
    )
}

export default Settings;