import becomeJabuDriver from '../../assets/Images/jabu1.png';
import drivers_icon from '../../assets/Images/driver-icon.png';
import pdfIcon from '../../assets/Images/pdf-icon.png';
import mainLogo from "../../assets/Images/logo.png";
import congrats from '../../assets/Images/congrats.gif';
// banks
import fnb from '../../assets/Images/banks/fnb.png';
import baw from '../../assets/Images/banks/bankwindhoek.jpeg';
import ndb from '../../assets/Images/banks/nedbank.png';
import stb from '../../assets/Images/banks/standard-bank.png';

const images = {
    becomeJabuDriver,
    mainLogo,
    congrats,
    fnb,
    baw,
    ndb,
    stb,
    pdfIcon,
    drivers_icon
}

export default images;