import React from 'react';

const Copyright: React.FC = () => {
    return (
        <div className="copyright_container">
            <p>{`${new Date().getFullYear()}© Jabu Logistics`}</p>
        </div>
    )
}

export default Copyright;