import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Homepage from "../../../pages/homepage";
import Onboarding from "../../../pages/onboarding";
import Drivers from "../../../pages/drivers";
import Vehicles from "../../../pages/vehicles";
import Profile from "../../../pages/profile";
import Settings from "../../../pages/settings";
import Earnings from "../../../pages/earnings";
import Settlement from "../../../pages/settlement";
import Shipment from "../../../pages/shipment";
import Review from "../../../pages/under-review/review";
import Policy from "../../../pages/policy";
import AddVehicleView from "../../../pages/vehicles/add-vehicle";
import AddDriverPage from "../../../pages/drivers/AddDriverPage";

export default function ProtectedRoutes () {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Homepage />} path="/" />
                <Route element={<Onboarding />} path="/onboarding" />
                <Route element={<Review/>} path="/onboarding-home"/>
                <Route element={<Homepage />} path="/dashboard" />
                <Route element={<Drivers />} path="/drivers" />
                <Route element={<Vehicles />} path="/vehicles" />
                <Route element={<AddVehicleView />} path="/add-vehicle" />
                <Route element={<AddDriverPage />} path="/add-driver" />
                <Route element={<Profile />} path="/me" />
                <Route element={<Settings />} path="/settings" />
                <Route element={<Earnings />} path="/earnings" />
                <Route element={<Settlement />} path="/settlement" />
                <Route element={<Shipment />} path="/shipment" />
                <Route element={<Policy />} path="/policy" />
                <Route element={<Homepage />} path="*" />
            </Routes>
        </BrowserRouter>
    )
}