import {createApi} from "@reduxjs/toolkit/query/react";
import ProtectedServicesConfig from "../../config/protectedServices";
import URLS from "../../utils/constants/urls";
import {DriverProps} from "../../types/onboard-forms/driver-props";
import {BaseQueryResult} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const VehiclesApi = createApi({
    reducerPath: 'VehiclesApi',
    baseQuery: ProtectedServicesConfig,
    tagTypes: ['Vehicles'],
    endpoints: (builder) => ({
        getAllVehicles: builder.query<any, void>({
            query: () => URLS.Vehicles,
            transformResponse: (response:BaseQueryResult<any>) => {
                return response.result;
            },
            providesTags: ['Vehicles']
        }),
        createVehicle: builder.mutation<DriverProps[], any>({
            query: (payload) => ({
                url: URLS.Vehicles,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['Vehicles']
        }),
        updateVehicle: builder.mutation<DriverProps[], any>({
            query: (payload) => ({
                url: `${URLS.Vehicles}/${payload.Vehicle_id}`,
                method: 'PATCH',
                body: payload.formData
            }),
            invalidatesTags: ['Vehicles']
        }),
        deleteVehicle: builder.mutation<any, { VehicleId: string }>({
            query: (params) => ({
                url: `${URLS.Vehicles}/${params.VehicleId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Vehicles']
        }),
    })
})

export const {
    useGetAllVehiclesQuery,
    useCreateVehicleMutation,
    useUpdateVehicleMutation,
    useLazyGetAllVehiclesQuery,
    useDeleteVehicleMutation
} = VehiclesApi;