import {useEffect, useState} from 'react'
import driverService from "../services/driver.service";
import MainApi from "../services/axiosInstance";
import Urls from "../utils/constants/urls";
import {csvJSON} from "../utils/helpers";


export const UseDrivers = () => {
    const [drivers, setDrivers] = useState([])
    const [driversOptions, setDriversOptions] = useState<any>([])
    const [loadingDrivers, setLoadingDrivers] = useState(true)
    const [totalDrivers, setTotalDrivers] = useState(0)
    const [afterId, setAfterId] = useState<string | null>(null)

    const fetchDrivers = async (pageNumber?: number) => {
        setLoadingDrivers(true)
        await driverService.getAllDrivers(pageNumber ? afterId : null).then((response) => {
            if (response && response.data.message === "success") {
                setTotalDrivers(response.data?.numRows)
                setAfterId(response.data?.afterId)
                setDrivers(response.data.result);
            }
        });
    }

    const fetchForOptions = async () => {
        await MainApi.get(Urls.drivers + '?isCsvResult=true').then((res) => {
            const results = csvJSON(res.data)
            setDriversOptions(results)
        })
    }

    useEffect(() => {
        fetchDrivers().then(() => setLoadingDrivers(false))
        fetchForOptions()
    }, [])

    const refetchDrivers = () => {
        // page number used here is not real
        fetchDrivers(2).then(() => setLoadingDrivers(false))
    }

    const restartPaging = async () => {
        setAfterId(null)
        await fetchDrivers()
    }

    return {
        loadingDrivers,
        drivers,
        totalDrivers,
        refetchDrivers,
        afterId,
        restartPaging,
        driversOptions
    }
}