import React, {useEffect} from "react";
import StepFormHead from "./step-form-head";
import {Card, List, ListItemText, ListItemIcon, ListItem} from "@mui/material";
import {CheckIcon} from "@heroicons/react/solid";
import staticData from "../../../utils/constants/static-texts.json";
import PrimaryButton from "../../widgets/primary-button";
import {OnboardingStepFormComponentProps} from "../../../types/onboard-forms/form-component-props";
import {Alert, Checkbox} from "antd";
import {saveStep} from "../../../utils/helpers";


const OnboardingSummary: React.FC<OnboardingStepFormComponentProps> = ({userInfo}) => {
    const [accepted, setAccepted] = React.useState(false);

    const handleAgreeTerms = () => {
        setAccepted(!accepted);
    };

    useEffect(() => {
        if (userInfo && userInfo.onboardingStatus === 'DONE_ONBOARDING') {
            setAccepted(true)
        }
    }, [userInfo]);


    const handleFinalizeSteps = async () => {
        saveStep("DONE_ONBOARDING").then(
            () => (window.location.href = "/onboarding-home")
        );
    };

    return (
        <>
            <div>
                <StepFormHead
                    title="Summary"
                    subTitle="Please read carefully our terms and conditions and agree to finish your sign up"
                />
                <Card>
                    <List>
                        {staticData.terms_conditions.map((item: string) => (
                            <ListItem key={item}>
                                <ListItemIcon>
                                    <CheckIcon className="text-green-400 w-6"/>
                                </ListItemIcon>
                                <ListItemText>{item}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Card>
                <div className="flex justify-center items-center bg-white font-bold p-5 my-4 text-gray-600">
                    <Checkbox checked={accepted} onChange={handleAgreeTerms}>
                        {`By proceeding, you automatically agree that you have read
                    carefully read our terms and conditions.`}
                    </Checkbox>
                </div>
                {userInfo.onboardingStatus === "DONE_ONBOARDING" &&
                !userInfo.idDocument ? (
                    <>
                        <Alert
                            type="warning"
                            closable={false}
                            className="my-4"
                            message="Warning"
                            description="You are seeing this message, because you completed last step of this application."
                        />
                    </>
                ) : (
                    <div className="w-full flex justify-end items-end">
                        <div>
                            <PrimaryButton
                                buttonText="Click here"
                                type="button"
                                onClicked={handleFinalizeSteps}
                                disabled={!accepted}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default OnboardingSummary;
