import {createSlice} from '@reduxjs/toolkit'
import {RootState} from "../../store";


interface DriversStatsProps {
    totalShipments: number
    totalOrders: number
    totalEarnings: number
    deliveredOrders: string
}

const defaultState: DriversStatsProps = {
    totalShipments: 0,
    totalOrders: 0,
    totalEarnings: 0,
    deliveredOrders: ''
}

const DriversStatsSlice = createSlice({
    name: 'driversStats',
    initialState: defaultState,
    reducers: {}
})

export const selectDriverState = (state: RootState) => state.driversStats;

export default DriversStatsSlice.reducer