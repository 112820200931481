import React, {useEffect, useState} from 'react'
import StepFormHead from "../features/onboarding/step-form-head";
import {InfoCircleOutlined} from '@ant-design/icons'
import {Alert, Card, Form, Input, Select, Tooltip} from 'antd'
import staticData from "../../utils/constants/static-texts.json";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {roleOptions} from "../../utils/helpers";
import userService from "../../services/user.service";
import PrimaryButton from "../widgets/primary-button";
import {useUpdateOnboardStep} from "../../hooks/parner_details_update";
import onboardingSteps from "../../utils/onboarding-steps.json";


const OnboardingStep4 = () => {

    const [form] = Form.useForm()
    const {Item} = Form
    const {userInfo, loading} = useCurrentUser()
    const [saving, setSaving] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationType, setNotificationType] = useState<'error' | 'success'>('error')
    const {increment} = useUpdateOnboardStep();
    const businessTipText = `If you don't have a business name, you can put your personal number or name followed by "Deliveries" e.g.: Marta's Deliveries`

    useEffect(() => {
        if (userInfo && userInfo.businessDetails) {
            form.setFieldsValue({
                businessName: userInfo.businessDetails?.businessName,
                role: userInfo.businessDetails?.role,
                city: userInfo.businessDetails?.city
            })
        }
    }, [userInfo])

    const handleSubmit = async (values: any, goNext?: boolean) => {
        setSaving(true)
        const payload = {
            businessDetails: {
                businessName: values.businessName,
                role: values.role,
                city: values.city
            },
            onboardingStatus: 'STEP_5',
        }

        await userService.updatePartnerDetails(payload).then((response) => {
            if (response && response.data.message === 'success') {
                setNotificationMessage('Information saved successfully!')
                setNotificationType('success')
                if (goNext) {
                    increment(onboardingSteps.indexOf("Summary"))
                }
            } else {
                setNotificationMessage(`We couldn't save your information now, please try again later.`)
                setNotificationType('error')
            }
        })
        setSaving(false)
    }


    return (
        <div>
            <StepFormHead
                title="A bit more about your business"
                subTitle="Help us understand your business by providing the following details."
            />
            <Card>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={values => handleSubmit(values)}
                    wrapperCol={{span: 24}}
                    disabled={loading}
                    labelCol={{span: 24}}
                >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <Item
                            name="businessName"
                            rules={[{required: true, message: 'Business name is required.'}]}
                            label="Business name"
                        >
                            <Input
                                size="large"
                                placeholder="e.g: Jabu Logistics"
                                // suffix={
                                //     <Tooltip title={businessTipText}>
                                //         <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
                                //     </Tooltip>
                                // }
                            />
                        </Item>
                        <Item
                            name="city"
                            rules={[{required: true, message: 'Business city is required.'}]}
                            label="City"
                        >
                            <Select
                                showSearch
                                placeholder="e.g: Windhoek"
                                optionLabelProp="name"
                                optionFilterProp="name"
                                size="large"
                                options={userInfo?.country && userInfo?.country === 'Zambia' ? staticData.zambia_cities : userInfo?.country === 'South Africa' ? staticData.south_africa_cities : staticData.namibia_cities}
                            />
                        </Item>
                        <Item
                            name="role"
                            rules={[{required: true, message: 'Business role is required.'}]}
                            label="Your role in business"
                        >
                            <Select
                                showSearch
                                placeholder="e.g: Owner"
                                size="large"
                                options={roleOptions}
                            />
                        </Item>
                    </div>

                    <Item className="col-span-2">
                        <hr/>
                        {notificationMessage && (
                            <Alert message={notificationMessage} type={notificationType} showIcon/>
                        )}
                    </Item>
                    <Item className="col-span-2">
                        <div className="footer_buttons_container">
                            <div className="button_container">
                                <PrimaryButton
                                    disabled={false}
                                    buttonText={loading ? 'Loading ...' : 'NEXT STEP'}
                                    onClicked={() => handleSubmit(form.getFieldsValue(), true)}
                                />
                            </div>
                        </div>
                    </Item>
                </Form>
            </Card>
        </div>
    )
}

export default OnboardingStep4;