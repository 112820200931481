import React, {useState} from 'react';
import styles from "./authentication.module.scss";
import AuthWrapper from "./auth-wrapper";
import InputField from "../../Components/widgets/input-field";
import Alert from "@mui/material/Alert";
import {Link} from "react-router-dom";
import VerifyLogin from "../../Components/features/authentication/verifyLogin";
import axios, {AxiosResponse} from "axios";
import urls from "../../utils/constants/urls";
import staticTexts from "../../utils/constants/static-texts.json";

const Login: React.FC = () => {
    const [phones, setPhones] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [showVerification, setShowVerification] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)

    const formTitle = showVerification ? "Verify your phone number" : "Sign in to your account";
    const buttonText = loading ? 'Please wait' : 'Continue';

    const isValid = () => {
        if (!loading) {
            return (phones.length > 10);
        }
    }

    const handleLogin = async () => {
        localStorage.clear()
        setErrorMessage('')
        if (isValid()) {
            setLoading(true)
            try {
                await axios.post(`${urls?.root_url}/partners/login`, {phone: phones}).then((response: AxiosResponse<any>) => {
                    if (response && response.data.message === 'success') {
                        const {token} = response.data.result
                        if (token) {
                            localStorage.setItem('verificationToken', token)
                            setLoading(false)
                            setShowVerification(true)
                        }
                    }
                })
            } catch (e: any) {
                setLoading(false)
                if (e.response && e.response.status === 409) {
                    setErrorMessage(e.response?.data?.message)
                } else {
                    setErrorMessage(staticTexts.messages.errorMessageResponse)
                }
            }
        }
    }

    return (
        <div className={styles.auth_container}>
            <AuthWrapper>
                <div className={styles.form_container}>
                    <div className="title_bar">
                        <span className="text-2xl font-bold">{formTitle}</span>
                        {showVerification && (
                            <p>
                                Please enter the <strong>5-digits</strong> code sent to you by sms.
                            </p>
                        )}
                    </div>
                    {showVerification ? (
                        <VerifyLogin/>
                    ) : (
                        <div>
                            <InputField
                                value={phones}
                                label="Phone number"
                                handleChange={(value: string) => value ? setPhones(value) : null}
                                inputType="phone_number"
                            />
                            <div className={styles.form_simple_text_row}>
                                <p>
                                    {`By proceeding, you will receive a confirmation SMS and data fees may apply.`}
                                </p>
                            </div>
                            {errorMessage && (
                               <div className="my-4"> <Alert severity="warning">{errorMessage.includes('signup') ? <p>{`A user with this phone number doesn't exist, please `}<a href="/signup" className="text-jc-secondary cursor-pointer hover:text-jc-primary underline">signup</a> {'and try again.'}</p> : errorMessage } </Alert></div>
                            )}
                            <button
                                type="button"
                                className={`primary_button ${
                                    !isValid() ? 'disabled_comp' : ''
                                }`}
                                disabled={!isValid()}
                                onClick={handleLogin}
                            >
                                {buttonText}
                            </button>
                        </div>
                    )}
                    <div className={styles.form_footer_text}>
                        <span>{`Don't have an account?`}</span>
                        <Link to="/signup" className={styles.link}>{`Signup`}</Link>
                    </div>
                </div>
            </AuthWrapper>
        </div>
    )
}

export default Login;