import axios, {InternalAxiosRequestConfig} from "axios";
import URLS from "../utils/constants/urls";

const BASE_URL = `${URLS.root_url}`;

const getAccessToken = () => {
    return localStorage.getItem('accessToken')
}

const MainApi = axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json'
    }
})

MainApi.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token = getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default MainApi;