import React from 'react';
import {Card} from 'antd'
import StepAddDriver from "../../Components/forms/step-add-driver";
import DashboardWrapper from "../../Components/widgets/dashboard_wrapper";


const AddDriverPage = () => {
    return (
        <DashboardWrapper>
            <div className="md:px-14 px-6 md:my-10">
                <Card actions={[<a href="/drivers" key="close">Close</a>]} title="Add new driver" style={{width: "100%"}}>
                    <StepAddDriver/>
                </Card>
            </div>
        </DashboardWrapper>
    )
}

export default AddDriverPage;