import React, {FC, useState, useEffect} from 'react'
import {Form, Input, Card, Select, Alert} from 'antd'
import {citiesOptions, genderOptions, handleNotification} from "../../utils/helpers";
import {countries, countriesOptions} from "../../utils/constants/countries";
import statics from "../../utils/constants/static-texts.json";
import SuburbField from "../features/suburbField";
import {OnboardingStep1FormProps} from "../../types/onboard-forms/step1";
import userService from "../../services/user.service";
import onboardingSteps from "../../utils/onboarding-steps.json";


interface Props {
    userDetails: any
}

const PersonalDetails: FC<Props> = ({userDetails}) => {
    const [loading, setLoading] = useState(false)
    const {Item} = Form
    const [form] = Form.useForm()
    const [selectedCountry, setSelectedCountry] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationType, setNotificationType] = useState<'success' | 'error'>('success')

    useEffect(() => {
        if (userDetails) {
            form.setFieldsValue({
                firstName: userDetails?.firstName,
                lastName: userDetails?.surName,
                email: userDetails?.email,
                suburb: userDetails?.address?.suburb,
                erf: userDetails?.address?.erf,
                street: userDetails?.address?.street,
                idNumber: userDetails?.idNumber,
                gender: userDetails?.gender,
                country: userDetails?.country,
                city: userDetails?.city,
            })
            setSelectedCity(userDetails?.city)
            setSelectedCountry(userDetails?.country)
        }
    }, [userDetails]);

    const handleSubmit = async (values: OnboardingStep1FormProps) => {
        setNotificationMessage('')
        const payload = {
            country: values.country,
            city: selectedCity,
            firstName: values.firstName,
            surName: values.lastName,
            gender: values.gender,
            email: values.email,
            idNumber: values.idNumber,
            address: {
                suburb: values.suburb,
                erf: values.erf,
                street: values.street,
            }
        }
        //     save the date to server
        setLoading(true)
        try {
            await userService.updatePartnerDetails(payload).then((response) => {
                if (response && response.data.message === 'success') {
                    setNotificationMessage('Information saved successfully!')
                    setNotificationType('success')
                    setLoading(false)
                    handleNotification('Saved information', 'success')
                } else {
                    setNotificationMessage(`We couldn't save your information now, please try again later.`)
                    setNotificationType('error')
                    setLoading(false)
                    setTimeout(() => {
                        setNotificationMessage('')
                    }, 4000)
                }
            })
        } catch (e: any) {
            setNotificationMessage(e.response.data?.message)
            setNotificationType('error')
            setLoading(false)
        }
    }


    return (
        <Card>
            <Form
                form={form}
                layout="vertical"
                wrapperCol={{span: 24}}
                labelCol={{span: 24}}
                onFinish={handleSubmit}
                disabled={!userDetails}
            >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    <Item
                        name="firstName"
                        rules={[{required: true, message: 'Please enter your first name'}]}
                        label="First name"
                    >
                        <Input size="large" placeholder="Your first name"/>
                    </Item>
                    <Item
                        rules={[{required: true, message: 'Please enter your last name'}]}
                        label="Last name"
                        name="lastName"
                    >
                        <Input size="large" placeholder="Your last name"/>
                    </Item>
                    <Item
                        rules={[{required: true, message: 'Gender is required!'}]}
                        label="Gender"
                        name="gender"
                    >
                        <Select
                            showSearch
                            size="large"
                            options={genderOptions}
                        />
                    </Item>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <Item rules={[{required: true, message: 'This is required.'}]} label="ID or passport number"
                          name="idNumber">
                        <Input size="large" placeholder="e.g: 000000"/>
                    </Item>
                    <Item
                        rules={[{required: true, message: 'This should be a valid email.'}]}
                        label="Email address"
                        name="email"
                    >
                        <Input size="large" placeholder="e.g: you@email.com"/>
                    </Item>
                </div>
                <Item className="col-span-3">
                    <h1 className="font-bold">Personal address</h1>
                    <hr/>
                </Item>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <Item
                        rules={[{required: true, message: 'Country is required!'}]}
                        label="Country"
                        name="country"
                    >
                        <Select
                            showSearch
                            onChange={(value) => setSelectedCountry(value)}
                            placeholder="e.g: Namibia"
                            size="large"
                            options={countriesOptions}
                        />
                    </Item>
                    <Item label="City" required name="city"
                          rules={[{required: true, message: 'City is required.'}]}>
                        <Select
                            disabled={selectedCountry === ''}
                            onChange={value => (form.setFieldValue('city', value), (setSelectedCity(value)))}
                            options={citiesOptions(selectedCountry === countries.Zambia ? statics.zambia_cities : selectedCountry === countries.Namibia ? statics.namibia_cities : selectedCountry === countries.South_Africa ? statics.south_africa_cities : [])}
                            placeholder="e.g: Windhoek" size="large"/>
                    </Item>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    <SuburbField
                        city={selectedCity} onSuburbChange={(value: string) => form.setFieldValue('suburb', value)}
                    />
                    <Item
                        label="ERF/Plot number"
                        name="erf"
                    >
                        <Input size="large" placeholder="e.g: 2300"/>
                    </Item>
                    <Item
                        rules={[{required: true, message: 'Street is required'}]}
                        label="Street"
                        name="street"
                    >
                        <Input size="large" placeholder="e.g: Bismarck st"/>
                    </Item>
                </div>
                <Item className="col-span-3">
                    <hr/>
                    {notificationMessage && (
                        <Alert message={notificationMessage} type={notificationType} showIcon/>
                    )}
                </Item>
                <div className="flex justify-end items-center">
                    <div className="md:w-2/12">
                        <button disabled={loading} type="submit"
                                className="primary_button">{loading ? 'please wait ...' : 'Save changes'}</button>
                    </div>
                </div>
            </Form>
        </Card>
    )
}

export default PersonalDetails;