const root_url = process.env.REACT_APP_ROOT_URL;
const send_otp = '/otp/resend';
const verify_otp = '/otp/verify';
const signup = '/signup';
const login = '/login';
// protected routes
const updateUser = '/partners/me';
const drivers = '/drivers';
const Vehicles = '/vehicles';
const signedUrl = '/media/signed-url';
const profile = '/partners/me';
const statistics = '/statistics';
const shipment_report = '/shipments/reports';
const earnings_report = '/earnings/reports';

export default {
    root_url,
    send_otp,
    verify_otp,
    signup,
    login,
    updateUser,
    drivers,
    Vehicles,
    signedUrl,
    profile,
    statistics,
    shipment_report,
    earnings_report
}