import React, {FC} from 'react';
import InclinedDecoration from "../../Components/widgets/inclined-decoration";
import styles from "./authentication.module.scss";
import AuthLeftSide from "../../Components/widgets/auth-left-side";
import images from "../../utils/constants/assets";

interface Props {
    children: React.ReactNode;
}

const AuthWrapper: FC<Props> = ({children}: Props) => {
    return (
        <>
            <InclinedDecoration/>
            <div className={styles.auth_container}>
                <AuthLeftSide/>
                <div className={styles.right_container}>
                    <div className={styles.logo_container}>
                        <img  src={images.mainLogo} alt="logo" />
                    </div>
                    {children}
                </div>
            </div>
        </>
    )
}

export default AuthWrapper;