import React, {FC, useEffect, useState} from 'react';
import {Form, Input, Select} from 'antd';
import MainApi from "../../services/axiosInstance";

const {Item} = Form

interface SuburbFieldProps {
    city: string
    onSuburbChange: (name: string) => void
}

interface SuburbProps {
    label: string
    value: string
}


const SuburbField: FC<SuburbFieldProps> = ({city, onSuburbChange}) => {
    const [suburbsOptions, setSuburbsOptions] = useState<SuburbProps[]>([])
    const [loading, setLoading] = useState(false)

    const fetchSuburbs = async (cityName: string) => {
        setLoading(true)
        const res = await MainApi.get(`/location/suburbs?cityName=${cityName}`)
        if (res && res.data.message === 'success') {
            const options = res.data.result.map((sub: { name: string }) => ({label: sub.name, value: sub.name}))
            setSuburbsOptions(options)
        }
    }

    useEffect(() => {
        if (city) {
            fetchSuburbs(city).then(() => setLoading(false))
        }
    }, [city])

    return (
        <>
            <Item
                rules={[{required: true, message: 'Suburb is required'}]}
                label="Suburb"
                name="suburb"
            >
                {suburbsOptions.length > 0 && !loading ? (
                        <Select
                            showSearch
                            disabled={city === ''}
                            options={suburbsOptions}
                            loading={loading}
                            onSelect={(value: string) => onSuburbChange(value)}
                            placeholder="e.g: Katutura"
                            size="large"
                        />
                    ) :
                    <Input
                        disabled={city === ''}
                        size="large"
                        placeholder="e.g: Katutura"
                    />
                }
            </Item>
        </>
    )
}

export default SuburbField;