import React, {FC} from 'react';
import {Step, StepLabel, Stepper} from "@mui/material";
import onboardingSteps from "../../../utils/onboarding-steps.json";
import {selectStepNavState} from "../../../redux/steps-navigation/steps-nav-slice";
import {useAppSelector} from "../../../redux/user-redux";
import {useUpdateOnboardStep} from "../../../hooks/parner_details_update";

const StepsNavigation: FC = () => {
    const {currentStepIndex} = useAppSelector(selectStepNavState);
    const {increment} = useUpdateOnboardStep()

    const handleNav = (stepIndex: number, step: string) => {
        if (stepIndex < currentStepIndex) {
            increment(onboardingSteps.indexOf(step))
        }
    }

    const handleStepsArray = () => {
        return onboardingSteps
    }


    return (
        <div>
            <Stepper
                orientation="vertical"
                activeStep={currentStepIndex}
            >
                {handleStepsArray().map((step: string, index: number) => (
                    <Step
                        style={{cursor: "pointer"}}
                        key={index}
                        onClick={() => handleNav(index, step)}>
                        <StepLabel>{step}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}

export default StepsNavigation;