import {createApi} from "@reduxjs/toolkit/query/react";
import ProtectedServicesConfig from "../../config/protectedServices";
import URLS from "../../utils/constants/urls";
import {BaseQueryResult} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {logoutUser} from "../../utils/helpers";

export const StatisticsApi = createApi({
    reducerPath: 'StatisticsApi',
    baseQuery: ProtectedServicesConfig,
    tagTypes: ['statistics', 'shipments'],
    endpoints: (builder) => ({
        getStats: builder.query<any, void>({
            query: () => URLS.statistics,
            transformResponse: (response:BaseQueryResult<any>) => {
                const {result} = response;
                return result;
            },
            transformErrorResponse: (error) => {
               if (error.status === 401) {
                   logoutUser()
               }
            }
        }),
    })
})

export const {useGetStatsQuery} = StatisticsApi;