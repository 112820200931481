import React from 'react'
import styles from "../authentication/authentication.module.scss";
import images from "../../utils/constants/assets";
import {Link} from "react-router-dom";
import PrivacyPolicy from "../../Components/features/policy";


const Policy: React.FC = () => {
    return (
        <div className="p-4 md:p-8 bg-gray-100">
            <Link to="/">
                <div className={styles.logo_container}>
                    <img src={images.mainLogo} alt="jabu-driver"/>
                </div>
            </Link>
            <PrivacyPolicy/>
        </div>
    )
}

export default Policy;