import React, {FC} from 'react';
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const customStyles = {
    control: (base: any) => ({
        ...base,
        height: 44,
        minHeight: 35,
        borderRadius: 7
    })
};


interface OptionProps {
    label: string
    value: string | number
}
interface Props {
    options: OptionProps[]
    label: string
    defaultValue?:OptionProps
    onChanged: any
    disabled?: boolean
    required?: boolean
}

const OptionsSelect: FC<Props> = ({label, options, defaultValue, onChanged,disabled, required}: Props) => {
    return (
        <div className="min-w-[250px] w-full">
            <label htmlFor="input">{label} {required && (<span className='text-red-500'>*</span>)}</label>
            <Select
                defaultValue={defaultValue}
                components={animatedComponents}
                required={required}
                options={options}
                isDisabled={disabled}
                styles={customStyles}
                onChange={(selected_object) => onChanged(selected_object?.value)}
                isMulti={false}
            />
        </div>
    )
}

export default OptionsSelect;