import React, {FC, useEffect, useState} from 'react';
import ImageUpload from "../widgets/image-upload";
import {Alert, Card, Form} from "antd";
import PrimaryButton from "../widgets/primary-button";
import userService from "../../services/user.service";
import onboardingSteps from "../../utils/onboarding-steps.json";


const DocumentsDetails: FC<{ userDetails: any }> = ({userDetails}) => {
    const [loading, setLoading] = useState(false)
    const [notification, setNotification] = useState<{ message: string, type: 'success' | 'error' }>({
        message: '',
        type: 'success'
    })
    const [initialValues, setInitialValues] = useState({
        documents_driver_license_front: "",
        documents_driver_license_back: "",
        documents_id_document_front: "",
        documents_id_document_back: "",
        documents_bank_confirmation: "",
    })

    useEffect(() => {
        if (userDetails) {
            setInitialValues({
                documents_driver_license_front: userDetails?.driverLicense?.front,
                documents_driver_license_back: userDetails?.driverLicense?.back ? userDetails?.driverLicense?.back : '',
                documents_id_document_front: userDetails?.idDocument?.front ? userDetails?.idDocument?.front : '',
                documents_id_document_back: userDetails?.idDocument?.back ? userDetails?.idDocument?.back : '',
                documents_bank_confirmation: userDetails?.bankConfirmationLetter ? userDetails?.bankConfirmationLetter : '',
            })
        }
    }, [userDetails])

    const isFormValid = () => {
        if (initialValues.documents_bank_confirmation
            && initialValues.documents_id_document_front
            && initialValues.documents_driver_license_back
            && initialValues.documents_driver_license_front
            && initialValues.documents_id_document_back
        ) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = async (values: any) => {
        setLoading(true)
        setNotification({message: '', type: 'success'})
        const payload = {
            idDocument: {
                front: initialValues.documents_id_document_front,
                back: initialValues.documents_id_document_back
            },
            driverLicense: {
                front: initialValues.documents_driver_license_front,
                back: initialValues.documents_driver_license_back
            },
            bankConfirmationLetter: initialValues.documents_bank_confirmation,
        }

        try {
            await userService.updatePartnerDetails(payload).then((response) => {
                if (response && response.data.message === 'success') {
                    setNotification({message: 'All documents are saved successfully!', type: 'success'})
                    setLoading(false)
                } else {
                    setNotification({
                        message: `We are not able to save your bank details now, please try again`,
                        type: "error"
                    })
                }
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Form
            onFinish={handleSubmit}
            layout="vertical"
            wrapperCol={{span: 24}}
            labelCol={{span: 24}}
            disabled={!userDetails}
        >
            <Card>
                <section className="row_section">
                    <div className="upload_documents">
                        <h4>{`Driver's license`}</h4>
                        <div className="doc_section">
                            <ImageUpload
                                currentImage={initialValues.documents_driver_license_front}
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_driver_license_front: link,
                                    })
                                }
                                label="Front"
                            />
                            <ImageUpload
                                currentImage={initialValues.documents_driver_license_back}
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_driver_license_back: link,
                                    })
                                }
                                label="Back"
                            />
                        </div>
                    </div>
                    <div className="upload_documents">
                        <h4>{`National ID or passport`}</h4>
                        <div className="doc_section">
                            <ImageUpload
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_id_document_front: link,
                                    })
                                }
                                label="Front"
                                currentImage={initialValues.documents_id_document_front}
                            />
                            <ImageUpload
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_id_document_back: link,
                                    })
                                }
                                label="Back"
                                currentImage={initialValues.documents_id_document_back}
                            />
                        </div>
                    </div>
                    <div className="upload_documents">
                        <h4>{`Bank confirmation letter`}</h4>
                        <div className="doc_section">
                            <ImageUpload
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_bank_confirmation: link,
                                    })
                                }
                                label="Front"
                                currentImage={initialValues.documents_bank_confirmation}
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        <hr/>
                        {notification.message && (
                            <Alert
                                message={notification.message}
                                type={notification.type}
                                showIcon
                            />
                        )}
                    </div>
                </section>
                <div className="flex justify-end items-center mt-3">
                    <div className="md:w-2/12">
                        <button
                            type="submit"
                            className="primary_button"
                            disabled={!isFormValid() || loading}
                        >
                            {loading ? 'Please wait ...' : 'Save changes'}
                        </button>
                    </div>
                </div>
            </Card>
        </Form>
    )
}

export default DocumentsDetails