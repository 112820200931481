import React from 'react';
import {Skeleton} from "@mui/material";
import {Card} from 'antd';
import {isMorningOrAfternoon} from "../../../utils/helpers";

const WelcomeSection: React.FC<{ userInfo: any }> = ({userInfo}) => {
    return (
        <>
            <div className="mb-4 mt-10">
                {userInfo ? <div className="font-bold text-xl">
                    <p>{isMorningOrAfternoon()}, <span
                        className="capitalize">{`${userInfo?.firstName.toLowerCase()}`} 👋🏾</span></p>
                </div> : <div>
                    <Skeleton/>
                </div>}
            </div>
        </>
    )
}

export default WelcomeSection;