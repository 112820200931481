import React, {FC, useState} from 'react';
import MENU_DATA from '../../../utils/constants/dashboardMenuData.json';
import {Link} from "react-router-dom";
import {MdMenu} from "react-icons/md";
import {Drawer} from "antd";
import {logoutUser} from "../../../utils/helpers";

const MenuItem = ({label, link}: { label: string, link: string }) => {
    let pName = window?.location?.pathname;
    pName = pName === '/' ? '/dashboard' : pName
    const pArray = pName.split('/');
    const currentPageName = pArray[1];
    return (
        <span className={label.toLowerCase() === currentPageName ? 'active_menu' : ''}>
            <Link to={link}>
                <span>{label}</span>
            </Link>
        </span>
    )
}

const DashboardNavigation: FC = () => {
    const [activeMenu, setActiveMenu] = useState<boolean>(false)
    return (
        <>
            <div className="navigation_menu_container">
                {MENU_DATA.map(item => (
                    <MenuItem key={item.title} label={item.title} link={item.link}/>
                ))}
            </div>
            <div className="md:hidden">
                <div className="py-4 text-right flex justify-end px-6">
                    <MdMenu onClick={() => setActiveMenu(!activeMenu)}
                            className="text-gray-500 text-2xl text-left cursor-pointer hover:rotate-12"/>
                </div>
                {activeMenu && (
                    <Drawer title="Jabu connect - partner dashboard" placement="right" onClose={() => setActiveMenu(!activeMenu)}
                            open={activeMenu}>
                        <ul className="mobileMenu">
                            {MENU_DATA.map(item => (
                                <MenuItem key={item.title} label={item.title} link={item.link}/>
                            ))}
                        </ul>
                        <button className="primary_button_outlined" onClick={logoutUser}>Logout</button>
                    </Drawer>
                )}
            </div>
        </>
    )
}


export default DashboardNavigation;