import React from 'react';
import {Pie, measureTextWidth} from '@ant-design/plots';
import {Skeleton} from "antd";

const DriversStats: React.FC<{ statsData: any, loading: boolean }> = ({statsData, loading}) => {


    function renderStatistic(containerWidth: number, text: string, style: React.CSSProperties): string {
        const {width: textWidth, height: textHeight} = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

        let scale = 1;

        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    const data = [
        {
            type: 'Delivered orders',
            value: statsData.deliveredOrders,
        },
        {
            type: 'No delivered orders',
            value: statsData.totalOrders - statsData.deliveredOrders,
        },

    ];
    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        color: ['rgba(45,70,105,0.37)', '#2D4669'],
        radius: 1,
        innerRadius: 0.64,
        meta: {
            value: {
                formatter: (v: number) => `${v}`,
            },
        },
        label: {
            type: 'inner',
            offset: '-50%',
            style: {
                textAlign: 'center',
            },
            autoRotate: false,
            content: '{value}',
        },
        // statistic: {
        //     title: {
        //         offsetY: -4,
        //         customHtml: (container: HTMLElement, view: any, datum: any) => {
        //             const {width, height} = container.getBoundingClientRect();
        //             const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
        //             const text = datum ? datum.type : 'Total earnings';
        //             return renderStatistic(d, text, {
        //                 fontSize: 20,
        //             });
        //         },
        //     },
        //     content: {
        //         offsetY: 4,
        //         style: {
        //             fontSize: '32px',
        //         },
        //         customHtml: (container: HTMLElement) => {
        //             const {width} = container.getBoundingClientRect();
        //             const text = `${statsData.totalEarnings} N$`;
        //             return renderStatistic(width, text, {
        //                 fontSize: 24,
        //             });
        //         },
        //     },
        // },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
        ],
    };
    return <>
        {loading ? <Skeleton active/> : <Pie {...config} />}
    </>;
};

export default DriversStats;
