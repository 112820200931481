import React from 'react';
import {Image, Popconfirm, Table} from 'antd';
import {useDeleteVehicleMutation} from "../../redux/services/vehiclesApi";
import {handleNotification} from "../../utils/helpers";
import {MdCircle} from "react-icons/md";

interface Item {
    _id: string;
    VINNumber: string;
    brandName: string;
    ownerId: string;
}

interface VehiclesTableProps {
    data: Item[]
    loading: boolean
    refetch: any
    onStartEdit: any
}

const VehiclesTable: React.FC<VehiclesTableProps> = ({data, loading, onStartEdit, refetch}: VehiclesTableProps) => {
    const [deleteVehicle, {isLoading: deletingVehicle}] = useDeleteVehicleMutation()
    const handleDelete = (VehicleId: string) => {
        deleteVehicle({VehicleId}).then((res: any) => {
            if (res.data?.message === 'success') {
                handleNotification('success')
                refetch()
            }
        })
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Picture',
            dataIndex: 'photos',
            width: 90,
            render: (_: any, row: any) => {
                return (
                    <div>
                        <Image className="rounded" src={row.pictures?.front}/>
                    </div>
                )
            }
        },
        {
            title: 'Brand name',
            dataIndex: 'brandName',
        },
        {
            title: 'Plate number',
            dataIndex: 'plateNumber',
        },
        {
            title: 'Kilometers',
            dataIndex: 'kilometers',
        },
        {
            title: 'Weight (KG)',
            dataIndex: 'weight',
        },
        {
            title: 'Color',
            dataIndex: 'colour',
            editable: true,
        },

        {
            title: 'Actions',
            dataIndex: 'operation',
            render: (_: any, row: any) => {
                return (
                    <div className="space-x-3 flex flex-nowrap">
                        <Popconfirm okText="Yes" cancelText="No" okType="danger"
                                    title="Are you sure for deleting this vehicle?"
                                    onConfirm={() => handleDelete(row._id)}>
                            <button className="delete_button">{deletingVehicle ? <MdCircle className="animate-bounce"/>
                                : 'Delete'}
                            </button>
                        </Popconfirm>
                        <button className="edit_button" onClick={() => onStartEdit(row._id)}>
                            Edit
                        </button>
                    </div>

                )
            }
        },
    ];

    return (
        <>
            <Table
                rowKey="_id"
                bordered
                dataSource={data}
                columns={defaultColumns}
                loading={loading}
                scroll={{
                    x: 900,
                    y: 1200
                }}
            />
        </>
    );
};

export default VehiclesTable;