import React, {FC, useEffect, useState} from "react";
import StepFormHead from "../features/onboarding/step-form-head";
import {Form, Input, Card, Select, Alert} from 'antd'
import {useCurrentUser} from "../../hooks/useCurrentUser";
import staticData from "../../utils/constants/static-texts.json";
import {accountTypeOptions} from "../../utils/helpers";
import userService from "../../services/user.service";
import PrimaryButton from "../widgets/primary-button";
import {useUpdateOnboardStep} from "../../hooks/parner_details_update";
import onboardingSteps from "../../utils/onboarding-steps.json";
import {OnboardingStep1FormProps} from "../../types/onboard-forms/step1";



const OnboardingStep2: FC = () => {
    const {Item} = Form
    const [form] = Form.useForm()
    const {loading, userInfo} = useCurrentUser()
    const [saving, setSaving] = useState(false)
    const {increment} = useUpdateOnboardStep();

    const [notification, setNotification] = useState<{ message: string, type: 'success' | 'error' }>({
        message: '',
        type: 'success'
    })


    useEffect(() => {
        if (userInfo) {
            form.setFieldsValue({
                branchName: userInfo.bankDetails?.branchName,
                branchCode: userInfo.bankDetails?.branchCode,
                bankName: userInfo.bankDetails?.bankName,
                accountNumber: userInfo.bankDetails?.accountNumber,
                accountType: userInfo.bankDetails?.accountType
            })
        }
    }, [userInfo])


    const handleSubmit = async (values: any, goNext?: boolean) => {
        const payload = {
            branchName: values.branchName,
            branchCode: values.branchCode,
            bankName: values.bankName,
            accountNumber: values.accountNumber,
            accountType: values.accountType
        }
        setSaving(true)
        setNotification({message: '', type: 'success'})

        try {
            await userService.updatePartnerDetails({
                bankDetails: payload,
                onboardingStatus: 'STEP_3'
            }).then((response) => {
                if (response && response.data.message === 'success') {
                    setNotification({message: 'Bank details are saved successfully!', type: 'success'})
                    if (goNext) {
                        increment(onboardingSteps.indexOf("Documents upload"))
                    }
                } else {
                    setNotification({
                        message: `We are not able to save your bank details now, please try again`,
                        type: "error"
                    })
                }
            })
        } catch (e: any) {
            setNotification({message: e.response.data?.message, type: "error"})
            setSaving(false)
        } finally {
            setSaving(false)
        }
    }

    const validateForm = (values: OnboardingStep1FormProps, goNext?: boolean) => {
        form.validateFields().then(
            () => {
                handleSubmit(values, goNext)
            },
            () => {
                console.log('error')
            },
        );
    }


    return (
        <>
            <StepFormHead
                title="Add bank account"
                subTitle="Please add the bank account where you want to be paid for your services. This is the account we will use to make payments."
            />

            <Card>
                <Form
                    form={form}
                    layout="vertical"
                    wrapperCol={{span: 24}}
                    labelCol={{span: 12}}
                    disabled={!userInfo || saving}
                    onFinish={validateForm}
                >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <Item
                            label="Bank name"
                            name="bankName"
                            rules={[{required: true, message: 'Bank name is required!'}]}
                        >
                            <Select
                                showSearch
                                placeholder="Select your Bank"
                                size="large"
                                options={userInfo?.country && userInfo?.country === 'Zambia' ? staticData.zambiaBanks : userInfo?.country === 'South Africa' ? staticData.southAfricaBanks : staticData.namibiaBanks}
                            />
                        </Item>
                        <Item
                            label="Branch name"
                            name="branchName"

                            rules={[{required: true, message: 'Branch name is required!'}]}
                        >
                            <Input size="large" placeholder={"e.g: Capricorn Branch"}/>
                        </Item>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <Item
                            label="Branch code"
                            name="branchCode"
                            rules={[{required: true, message: 'Branch code is required!'}]}
                        >
                            <Input size="large" placeholder={"e.g: 48600"}/>
                        </Item>
                        <Item
                            label="Account number"
                            name="accountNumber"
                            rules={[{required: true, message: 'Account number is required!'}]}
                        >
                            <Input size="large" placeholder={"e.g: 8003758105"}/>
                        </Item>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <Item
                            label="Account type"
                            name="accountType"
                            rules={[{required: true, message: 'Account type is required!'}]}
                        >
                            <Select
                                showSearch
                                placeholder="eg: Checking"
                                size="large"
                                options={accountTypeOptions}
                            />
                        </Item>
                    </div>
                    <Item className="col-span-2">
                        <hr/>
                        {notification.message && (
                            <Alert message={notification.message} type={notification.type} showIcon/>
                        )}
                    </Item>
                    <Item className="col-span-2">
                        <div className="footer_buttons_container">
                            <div className="button_container">
                                <PrimaryButton
                                    disabled={false}
                                    buttonText={loading ? 'Loading ...' : 'NEXT STEP'}
                                    onClicked={() => validateForm(form.getFieldsValue(), true)}
                                />
                            </div>
                        </div>
                    </Item>
                </Form>
            </Card>

        </>
    )
}

export default OnboardingStep2;