import React, {ReactNode} from "react";
import loadingButtonAnimation from "../../utils/constants/lotties-animations/loading-button.json";
import Lottie from "lottie-react";

interface Props {
    buttonText: string
    onClicked: any
    disabled: boolean
    type?: "button" | "submit"
    icon?: ReactNode;
}

const PrimaryButton: React.FC<Props> = ({buttonText, onClicked, disabled, type, icon}: Props) => {
    return (
        <button
            type={type}
            className={`primary_button ${
                disabled ? 'disabled_comp' : ''
            }`}
            disabled={disabled}
            onClick={onClicked}
        >
            {disabled && buttonText === 'Please Wait' ? <div className="w-20 h-auto">
                <Lottie
                animationData={loadingButtonAnimation}
                loop={true}

            /></div> : buttonText}
            {icon && (
                <span className="animate-pulse mx-2">
                    {icon}
                </span>
            )}
        </button>
    )
}
export default PrimaryButton;