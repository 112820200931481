import React, {FC, useState} from 'react';
import {Document, Page, pdfjs} from "react-pdf";

import {MdUpload} from "react-icons/md";
// import images from "../../utils/constants/assets";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


const ThumbPreview: FC<{ currentFile: any }> = ({currentFile}) => {
    const [pageNumber, setPageNumber] = useState(1)

    const onDocumentLoadSuccess = ({numPages}: any) => {
        setPageNumber(numPages)
    }


    return (
        <div>
            {currentFile ? (
                <div className="flex flex-col justify-center items-center">
                    {currentFile.endsWith('.pdf') ? (
                        <div>
                            <Document file={currentFile} onLoadSuccess={onDocumentLoadSuccess}
                                      onLoadError={(error) => console.log(error)}>
                                <Page width={250} height={150} pageNumber={pageNumber}/>
                            </Document>
                            {/*<img*/}
                            {/*    src={images.pdfIcon}*/}
                            {/*    alt="image"*/}
                            {/*    className="w-[220px]"*/}
                            {/*/>*/}
                        </div>
                    ) : <img
                        src={currentFile}
                        alt="image"
                        className="w-[220px]"
                    />}
                </div>
            ) : <MdUpload/>}
        </div>
    )
}

export default ThumbPreview;