import {configureStore} from "@reduxjs/toolkit";
import {
    persistStore, persistReducer, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import { AuthApi } from "./services/authenticationApi";
import {setupListeners} from "@reduxjs/toolkit/query";
import {DriversApi} from "./services/driversApi";
import {VehiclesApi} from "./services/vehiclesApi";
import {UserApi} from "./services/userApi";
import {StatisticsApi} from "./services/statisticsApi";
import {VerificationApi} from "./services/verificationApi";
import {ReportsApi} from "./services/reportsApi";
import {EarningsService} from "./services/earnings.service";

const persistConfig = {
    key: 'root',
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat([
            AuthApi.middleware,
            DriversApi.middleware,
            VehiclesApi.middleware,
            UserApi.middleware,
            VerificationApi.middleware,
            StatisticsApi.middleware,
            ReportsApi.middleware,
            EarningsService.middleware
        ])
})

setupListeners(store.dispatch);
const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export {store, persistor};