import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import URLS from '../../utils/constants/urls';
import {SignupResponseProps} from "../../types/signup-responses";

export const AuthApi = createApi({
    reducerPath: 'AuthApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${URLS.root_url}/partners`,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('verificationToken');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
                headers.set('Content-Type', 'application/json')
            }
        }
    }),
    tagTypes: ['user'],
    endpoints: (builder) => ({
        loginUser: builder.mutation<{ phone: string }, any>({
            query: (payload) => ({
                url: URLS.login,
                method: 'POST',
                body: payload
            })
        }),
        requestForOtp: builder.mutation<{ phone: string }, any>({
            query: (payload) => ({
                url: URLS.send_otp,
                method: 'POST',
                body: payload
            })
        }),
        registerUser: builder.mutation<any, any>({
            query: (payload) => ({
                url: URLS.signup,
                method: 'POST',
                body: payload
            }),
            transformResponse: (res:SignupResponseProps) => {
                return res
            }
        }),

    })
})

export const {
    useLoginUserMutation,
    useRequestForOtpMutation,
    useRegisterUserMutation,
} = AuthApi;