import OTPInputField from "../../widgets/otp-input-field";
import React, {useState} from "react";
import axios, {AxiosResponse} from "axios";
import urls from "../../../utils/constants/urls";
import staticTexts from '../../../utils/constants/static-texts.json'
import {useNavigate} from "react-router-dom";
import Alert from "@mui/material/Alert";
import statics from "../../../utils/constants/static-texts.json";
import {useUpdateOnboardStep} from "../../../hooks/parner_details_update";
import ResendOtp from "./resend-otp";

export const VerifyLogin = () => {
    const [userCode, setUserCode] = useState<string[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('')
    const navigate = useNavigate();
    const {increment} = useUpdateOnboardStep()

    const fetchUserProfile = async () => {
        const accessToken = localStorage.getItem('accessToken')
        try {
            await axios.get(`${urls?.root_url}/partners/me`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then((response) => {
                if (response && response.data) {
                    const {result} = response.data
                    if (result.onboardingStatus === 'DONE_ONBOARDING' && result.verificationStatus === 'VERIFIED') {
                        window.location.href = '/dashboard'
                    } else if (result.onboardingStatus === 'PRE_ONBOARDING') {
                        increment(statics.MainOnBoardingSteps.indexOf("STEP_1"))
                        navigate('/onboarding')
                    } else {
                        increment(statics.MainOnBoardingSteps.indexOf(result.onboardingStatus))
                        navigate('/onboarding-home')
                    }
                    setLoading(false)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    const handleLoginOtpVerification = async () => {
        const verificationToken = localStorage.getItem('verificationToken')
        setLoading(true)
        try {
            await axios.post(`${urls?.root_url}/otp/verify`, {code: userCode}, {
                headers: {
                    'Authorization': `Bearer ${verificationToken}`
                }
            }).then((response: AxiosResponse<any>) => {
                if (response && response.data.message === 'success') {
                    const {token} = response.data.result
                    localStorage.removeItem('LoginVerificationToken')
                    localStorage.setItem('accessToken', token)
                    //     fetch the user profile to know where to go
                    fetchUserProfile()
                }
            })
        } catch (e) {
            setErrorMessage(staticTexts.messages.errorMessageResponse)
            setLoading(false)
        }
    }

    return (
        <div>
            <OTPInputField
                handleSubmission={handleLoginOtpVerification}
                handleInputs={(values: string[]) => setUserCode(values)}
            />
            <button
                className="primary_button"
                type="button"
                disabled={userCode?.length !== 5 || loading}
                onClick={handleLoginOtpVerification}
            >
                {loading ? 'Please wait' : 'Verify'}
            </button>
            {errorMessage && (
                <Alert severity="warning">{errorMessage}</Alert>
            )}
            <ResendOtp />
        </div>
    )
}

export default VerifyLogin;