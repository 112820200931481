import React, {useCallback, useEffect, useState} from 'react';
import {VehicleFormItemProps, VehicleProps} from "../../types/onboard-forms/vehicle-props";
import {useFormik} from "formik";
import VehicleSchema from "../../utils/form-validation/vehicle-schema";
import OptionsSelect from "../widgets/options-select";
import {colorsOptions, disabledFutureYears, VehicleTypesOptions} from "../../utils/helpers";
import InputField from "../widgets/input-field";
import ImageUpload from "../widgets/image-upload";
import {useUpdateVehicleMutation} from "../../redux/services/vehiclesApi";
import {Alert, Button, DatePicker, Input} from 'antd'
import vehicleService from "../../services/vehicle.service";
import {DriverProps} from "../../types/onboard-forms/driver-props";
import CarBrands from '../../utils/constants/car-brands.json';
import MotoBrands from '../../utils/constants/moto-brands.json';
import TruckBrands from '../../utils/constants/truck-brands.json';
import StaticTexts from '../../utils/constants/static-texts.json';
import dayjs from "dayjs";


interface AddVehicleProps {
    closeDialog?: any
    editableVehicle?: VehicleFormItemProps
    Vehicles: any
    drivers: any[]
    loadingDrivers: boolean
}

const AddVehicle: React.FC<AddVehicleProps> = ({editableVehicle, drivers, loadingDrivers, closeDialog}) => {
    const [updateVehicle, {isLoading: updating}] = useUpdateVehicleMutation();
    const [NotificationMessage, setNotificationMessage] = useState('')
    const [addingVehicle, setAddingVehicle] = useState(false);
    const [NotificationType, setNotificationType] = useState<'error' | 'success' | 'warning'>('success')


    const findDriverDefault = (id?: string) => {
        if (id) {
            const found = drivers.find((d: any) => d?._id === id)
            if (found) {
                return found
            }
        }

        return ''
    }

    const initialValues: VehicleProps = {
        type: editableVehicle ? editableVehicle.type : '',
        brandName: editableVehicle ? editableVehicle.brandName : '',
        yearModel: editableVehicle ? editableVehicle.yearModel : '',
        colour: editableVehicle ? editableVehicle.colour : '',
        weight: editableVehicle ? editableVehicle.weight : 1,
        VINNumber: editableVehicle ? editableVehicle.VINNumber : '',
        kilometers: editableVehicle ? editableVehicle?.kilometers : 1,
        plateNumber: editableVehicle ? editableVehicle?.plateNumber : '',
        pictureFront: editableVehicle ? editableVehicle?.pictures?.front : '',
        pictureBack: editableVehicle ? editableVehicle?.pictures?.back : '',
        pictureSide: editableVehicle ? editableVehicle?.pictures?.side : '',
        driverId: editableVehicle?.driverId || undefined,
    }

    const handleSubmit = async (values: VehicleProps) => {
        setAddingVehicle(true)
        setNotificationMessage('')
        const formData = {
            type: values.type.toUpperCase(),
            brandName: values.brandName,
            yearModel: values.yearModel,
            colour: values.colour,
            weight: values.weight,
            height: 0,
            width: 0,
            VINNumber: values.VINNumber,
            kilometers: values.kilometers,
            plateNumber: values.plateNumber,
            driverId: values.driverId,
            pictures: {
                front: values.pictureFront,
                back: values.pictureBack,
                side: values.pictureSide
            }
        }


        if (editableVehicle) {
            try {
                updateVehicle({Vehicle_id: editableVehicle._id, formData: formData}).then((response: any) => {
                    if (response && response.data.message === 'success') {
                        setNotificationMessage("Vehicle has been updated successfully")
                        setNotificationType("success")
                        setAddingVehicle(false)
                        if (closeDialog) {
                            setTimeout(closeDialog, 1000)
                        }
                    }
                })

            } catch (e: any) {
                setNotificationMessage(e.response?.data?.message)
                setNotificationType("error")
                setAddingVehicle(false)
            }
        } else {
            try {
                await vehicleService.addVehicle(formData).then((response) => {
                    if (response && response.data.message === 'success') {
                        setNotificationMessage("Vehicle has been added successfully")
                        setNotificationType("success")
                        setAddingVehicle(false)
                        if (closeDialog) {
                            setTimeout(closeDialog, 1000)
                        }
                    }
                })
            } catch (e: any) {
                setNotificationMessage(e.response?.data?.message)
                setNotificationType("error")
                setAddingVehicle(false)
            }
        }
    }

    const formik = useFormik({
        validationSchema: VehicleSchema,
        initialValues: initialValues,
        onSubmit: async (values) => {
            await handleSubmit(values)
        }
    })

    const setInputValue = useCallback(
        (key: string, value: any) =>
            formik.setValues({
                ...formik.values,
                [key]: value
            }),
        [formik]
    )

    const buttonText = editableVehicle ? 'Update' : 'Save'


    const driverOptions = () => {
        let options: any[] = []
        if (drivers && drivers.length > 0) {
            options = drivers.map((driver: DriverProps) => ({value: driver._id, label: driver.firstName}))
        }
        return options
    }


    return (
        <div>
            {drivers.length > 0 ? (
                    <form onSubmit={formik.handleSubmit}>
                        <div className="two_cols_row">
                            <div className="validated_field_col">
                                <OptionsSelect
                                    options={VehicleTypesOptions}
                                    defaultValue={{label: formik.values.type, value: formik.values.type}}
                                    label="Type of vehicle"
                                    required={true}
                                    onChanged={(value: string) => setInputValue('type', value)}
                                />
                                <small>{formik.touched.type && (formik.errors.type)}</small>
                            </div>
                            <div className="validated_field_col">
                                <OptionsSelect
                                    disabled={!formik.values.type}
                                    options={formik.values.type === "Motorcycle" ? MotoBrands : formik.values.type === "Car" ? CarBrands : TruckBrands}
                                    defaultValue={{label: formik.values.brandName, value: formik.values.brandName}}
                                    label="Brand name"
                                    required={true}
                                    onChanged={(value: string) => setInputValue('brandName', value)}
                                />
                                <small>{formik.touched.brandName && (formik.errors.brandName)}</small>
                            </div>
                            <div className="validated_field_col">
                                <label htmlFor="input">Year model <span className="text-red-500">*</span></label>
                                <DatePicker
                                    disabled={!formik.values.brandName}
                                    className="w-full"
                                    picker="year"
                                    format="YYYY"
                                    disabledDate={disabledFutureYears}
                                    defaultValue={formik.values.yearModel ? dayjs(`${formik.values?.yearModel}/01/01`, 'YYYY') : dayjs(`${new Date().getFullYear()}/01/01`, 'YYYY')}
                                    size="large"
                                    placeholder="Year model"
                                    onChange={(date, dateString) => (setInputValue('yearModel', dateString), formik.setFieldTouched('yearModel'))}
                                />
                            </div>
                            <div className="validated_field_col">
                                <OptionsSelect
                                    disabled={!formik.values.yearModel}
                                    options={StaticTexts.vehicleTypes}
                                    defaultValue={{label: `${formik.values?.weight?.toString() || 0}`, value: `${formik.values?.weight?.toString() || 0}`}}
                                    label={`Vehicle capacity`}
                                    required={true}
                                    onChanged={(value: string) => (setInputValue('weight', value), formik.setFieldTouched('weight'))}
                                />
                                <small>{formik.touched.weight && (formik.errors.weight)}</small>
                            </div>
                            <div className="validated_field_col">
                                <InputField
                                    value={formik.values.VINNumber}
                                    label={`VIN number`}
                                    handleChange={(value: string) => (setInputValue('VINNumber', value), formik.setFieldTouched('VINNumber'))}
                                    inputType="text"
                                />
                                <small>{formik.touched.VINNumber && (formik.errors.VINNumber)}</small>
                            </div>
                            <div className="validated_field_col">
                                <InputField
                                    value={formik.values.kilometers}
                                    label={`Kilometers`}
                                    handleChange={(value: string) => (setInputValue('kilometers', value), formik.setFieldTouched('kilometers'))}
                                    inputType="number"
                                />
                                <small>{formik.values.kilometers && (formik.errors.kilometers)}</small>
                            </div>
                            <div className="validated_field_col">
                                <InputField
                                    value={formik.values.plateNumber}
                                    label={`Plate number`}
                                    handleChange={(value: string) => (setInputValue('plateNumber', value), formik.setFieldTouched('plateNumber'))}
                                    inputType="text"
                                />
                                <small>{formik.touched.plateNumber && (formik.errors.plateNumber)}</small>
                            </div>
                            <div className="validated_field_col">
                                <OptionsSelect
                                    options={colorsOptions}
                                    defaultValue={{label: formik.values.colour, value: formik.values.colour}}
                                    label="Color"
                                    required={true}
                                    onChanged={(value: string) => (setInputValue('colour', value), formik.setFieldTouched('colour'))}
                                />
                                {/*<small>{formik.touched.colour && (formik.errors.colour)}</small>*/}
                            </div>
                            <div className="validated_field_col">
                                <OptionsSelect
                                    options={driverOptions()}
                                    defaultValue={{
                                        label: findDriverDefault(formik.values.driverId)?.firstName,
                                        value: findDriverDefault(formik.values.driverId)?._id,
                                    }}
                                    label="Driver"
                                    required={true}
                                    onChanged={(value: string) => (setInputValue('driverId', value), formik.setFieldTouched('driverId'))}
                                />
                                {/*<small>{formik.touched.driverId && (*/}
                                {/*    formik.errors.driverId*/}
                                {/*)}</small>*/}
                            </div>
                        </div>
                        <div className="my-10">
                            <div className="bg-sky-600 p-3 my-3 rounded-md text-white">
                                <strong>PICTURES</strong>
                            </div>
                            <div className="grid grid-cols-2 gap-3">
                                <div className="flex flex-col">
                                    <ImageUpload
                                        currentImage={formik.values.pictureFront}
                                        onLinkReady={(link: string) => formik.setFieldValue('pictureFront', link)}
                                        label="Front"
                                    />
                                    <small
                                        className='error_text'>{formik.touched.pictureFront && (formik.errors.pictureFront)}</small>
                                </div>
                                <div className="flex flex-col">
                                    <ImageUpload
                                        currentImage={formik.values.pictureBack}
                                        onLinkReady={(link: string) => formik.setFieldValue('pictureBack', link)}
                                        label="Back"
                                    />
                                    <small
                                        className='error_text'>{formik.touched.pictureBack && (formik.errors.pictureBack)}</small>
                                </div>
                                <div className="flex flex-col">
                                    <ImageUpload
                                        currentImage={formik.values.pictureSide}
                                        onLinkReady={(link: string) => formik.setFieldValue('pictureSide', link)}
                                        label="Side"
                                    />
                                    <small
                                        className='error_text'>{formik.touched.pictureSide && (formik.errors.pictureSide)}</small>
                                </div>
                            </div>
                            {NotificationMessage && (
                                <section className="my-10">
                                    <Alert

                                        message={NotificationType}
                                        type={NotificationType}
                                        description={NotificationMessage}
                                    />
                                </section>
                            )}
                            <div className="my-10 flex justify-end items-center gap-4">
                                <div className="w-[200px] flex justify-between items-center gap-4">
                                    <Button loading={addingVehicle} block size="large" htmlType="submit"
                                            disabled={addingVehicle || updating}
                                            type="primary">{buttonText}</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                ) :
                <h3 className="text-center font-bold animate-bounce">{loadingDrivers ? "Please wait ...." : "You need one driver to proceed."}</h3>}
        </div>
    )
}
export default AddVehicle;