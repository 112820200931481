import React, {FC, ReactNode, useEffect} from 'react';
import DashboardNavigation from "../features/navigation/dashboard_navigation";
import TopBar from "./topbar";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import DashboardFooter from "../features/footer";

interface Props {
    children: ReactNode;
}

const DashboardWrapper: FC<Props> = ({children}) => {
    const {userInfo} = useCurrentUser()

    useEffect(() => {
        if (userInfo && userInfo.onboardingStatus === 'DONE_ONBOARDING' && userInfo?.verificationStatus !== 'APPROVED') {
            window.location.href = '/onboarding-home'
        }

        if (userInfo && userInfo.onboardingStatus.startsWith("STEP")) {
            window.location.href = '/onboarding'
        }
    }, [userInfo])

    return (
        <>
            <TopBar/>
            <DashboardNavigation/>
            <div className="body_container">
                {children}
                <DashboardFooter />
            </div>
        </>
    )
}

export default DashboardWrapper;