import React from 'react';
import {MdCheckCircle} from "react-icons/md";
import {Alert, Tooltip} from 'antd'
import {BsQuestionCircle} from "react-icons/bs";


const OnboardingIntro = () => {

    const continueSteps = () => {
        localStorage.setItem('done_intro', 'done')
        window.location.reload()

    }

    return (
        <div className="onboarding_intro">
            <div className="intro_header">
                <h4>Congratulations </h4>
                <span>Thank you for making your first step on becoming Jabu connect partner!</span>
            </div>
            <div className="content_box">
                <p>
                    Before we proceed, please review the list of important things you should have in hand.
                </p>
                <ul>
                    <li>
                        <strong>
                            Personal documents:
                            <Tooltip
                                title="You will upload your personal documents, they can be in pdf, jpeg or png format.">
                                <BsQuestionCircle className="cursor-pointer text-gray-400"/>
                            </Tooltip>
                        </strong>
                    </li>
                    <li>
                        <div className="col">
                            <MdCheckCircle className="w-5 h-5  text-green-500"/>
                            <span>Driving License (front and back)</span>
                        </div>
                        <div className="col">
                            <MdCheckCircle className="w-5 h-5  text-green-500"/>
                            <span>National ID or Passport (front and back)</span>
                        </div>
                        <div className="col">
                            <MdCheckCircle className="w-5 h-5  text-green-500"/>
                            <span>Bank Confirmation Letter</span>
                        </div>
                    </li>
                </ul>
                <hr className="my-10"/>
                <Alert
                    message="Note"
                    description="You are welcome to continue your registration using the documents you have, and you can return later to complete the process."
                    type="info"
                    showIcon
                />
                <div className="flex justify-end py-5">
                    <button onClick={continueSteps} className="primary_button max-w-[120px]">Continue</button>
                </div>
            </div>
        </div>
    )
}

export default OnboardingIntro;