import {createApi} from "@reduxjs/toolkit/query/react";
import ProtectedServicesConfig from "../../config/protectedServices";
import URLS from "../../utils/constants/urls";
import {DriverProps} from "../../types/onboard-forms/driver-props";
import {BaseQueryResult} from "@reduxjs/toolkit/dist/query/baseQueryTypes";


export const DriversApi = createApi({
    reducerPath: 'DriversApi',
    baseQuery: ProtectedServicesConfig,
    tagTypes: ['drivers'],
    endpoints: (builder) => ({
        getAllDrivers: builder.query<any, void>({
            query: () => URLS.drivers,
            providesTags: ['drivers'],
            transformResponse: (response: BaseQueryResult<any>) => {
                return response.result;
            },
        }),
        createDriver: builder.mutation<DriverProps[], any>({
            query: (payload) => ({
                url: URLS.drivers,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['drivers']
        }),
        updateDriver: builder.mutation<DriverProps[], any>({
            query: (payload) => ({
                url: `${URLS.drivers}/${payload.id}`,
                method: 'PATCH',
                body: payload.formData
            }),
            invalidatesTags: ['drivers']
        }),
        deleteDriver: builder.mutation<any, { driverId: string }>({
            query: (params) => ({
                url: `${URLS.drivers}/${params.driverId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['drivers']
        }),
    })
})

export const {
    useGetAllDriversQuery,
    useCreateDriverMutation,
    useDeleteDriverMutation,
    useUpdateDriverMutation,
} = DriversApi;