import {combineReducers} from "@reduxjs/toolkit";
import onboardingReducer from "./onboarding/onboarding-slice";
import filtersReducer from './filters/filters-slice';
import stepNavigationReducer from "./steps-navigation/steps-nav-slice";
import VehiclesReducer from "./vehicles/vehicles-slice";
import DriversStatsReducer from './statistics/drivers/drivers-stats.slice';
import {AuthApi} from "./services/authenticationApi";
import {DriversApi} from "./services/driversApi";
import {VehiclesApi} from "./services/vehiclesApi";
import {UserApi} from "./services/userApi";
import {StatisticsApi} from "./services/statisticsApi";
import {VerificationApi} from "./services/verificationApi";
import {ReportsApi} from "./services/reportsApi";
import {EarningsService} from "./services/earnings.service";

const reducer = combineReducers({
    onboarding: onboardingReducer,
    stepNavigation: stepNavigationReducer,
    driversStats: DriversStatsReducer,
    filters: filtersReducer,
    Vehicles: VehiclesReducer,
    [AuthApi.reducerPath]: AuthApi.reducer,
    [DriversApi.reducerPath]: DriversApi.reducer,
    [VehiclesApi.reducerPath]: VehiclesApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [StatisticsApi.reducerPath]: StatisticsApi.reducer,
    [VerificationApi.reducerPath]: VerificationApi.reducer,
    [ReportsApi.reducerPath]:  ReportsApi.reducer,
    [EarningsService.reducerPath]:  EarningsService.reducer
})

export default reducer;