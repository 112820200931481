import React, { useState} from 'react';
import {Alert, Form, Input, Select, Checkbox} from "antd";
import {citiesOptions, genderOptions, handleNotification} from "../../utils/helpers";
import PhoneInput from "react-phone-number-input";
import {countries, countriesOptions} from "../../utils/constants/countries";
import statics from "../../utils/constants/static-texts.json";
import ImageUpload from "../widgets/image-upload";
import driverService from "../../services/driver.service";
import SuburbField from "../features/suburbField";


const StepAddDriver: React.FC<{ closeDialog?: () => void }> = ({closeDialog}) => {
    const [form] = Form.useForm()
    const {Item} = Form
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState('idNumber')
    const [selectedCountry, setSelectedCountry] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedIdFront, setSelectedIdFront] = useState('')
    const [selectedIdBack, setSelectedIdBack] = useState('')
    const [selectedDlFront, setSelectedDlFront] = useState('')
    const [selectedDlBack, setSelectedDlBack] = useState('')
    const [driverPhoto, setDriverPhoto] = useState('')
    const [notification, setNotification] = useState<{ message: string, type: 'success' | 'error' }>({
        message: '',
        type: 'success'
    })


    const initialValues = {
        firstName: '',
        surName: '',
        idNumber: '',
        phone: '',
        erf: '',
        suburb: '',
        street: '',
        idFront: ''
    }

    const handleSubmit = async (values: any) => {
        setLoading(true)
        const formData = {
            firstName: values.firstName,
            surName: values.surName,
            idNumber: values.idNumber,
            photo: values.photo,
            phone: `${values.phone}`,
            address: {
                erf: values.erf === '' ? '1314' : values.erf,
                suburb: values.suburb,
                street: values.street
            },
            gender: values.gender,
            idDocument: {
                front: values.idFront,
                back: values.idBack
            },
            driverLicense: {
                front: values.dlFront,
                back: values.dlBack
            },
            country: values.country,
            city: values.city
        }

        try {
            await driverService.createDriver(formData).then((response) => {
                console.log(response)
                handleNotification("Driver is created successfully", 'success')
                setNotification({message: "Driver is created successfully", type: 'success'})
                if (closeDialog) {
                    setTimeout(closeDialog, 2000)
                }
            })
        } catch (e: any) {
            const message = e.response?.data?.message
            setNotification({message, type: 'error'})
        } finally {
            setLoading(false)
        }
    }

    const handleChangePhone = (e: any) => {
        form.setFieldValue('phone', e)
    }


    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div>
            <Form
                name="validateOnly"
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinishFailed={onFinishFailed}
                onFinish={values => handleSubmit(values)}
            >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">

                    <Item validateStatus="" label="First name"
                          rules={[{required: true, message: 'First name is required.'}]}
                          name="firstName">
                        <Input size="large" placeholder="e.g: John"/>
                    </Item>
                    <Item rules={[{required: true, message: 'Surname is required.'}]} label="Surname" name="surName">
                        <Input size="large" placeholder="e.g: Wick"/>
                    </Item>
                    <Item rules={[{required: true, message: 'Gender is required.'}]} label="Gender" name="gender">
                        <Select
                            options={genderOptions}
                            placeholder="e.g: Male" size="large"/>
                    </Item>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <Item name="phone" rules={[{required: true, message: 'Phone number is required.'}]}
                          label="Phone number" required>
                        <PhoneInput
                            placeholder={"Enter phone number"}
                            value={form.getFieldValue('phone')}
                            countryCallingCodeEditable={false}
                            defaultCountry={"NA"}
                            onChange={(value) => handleChangePhone(value)}
                            international
                            limitMaxLength
                            style={{height: "41px", borderRadius: "8px"}}
                        />
                    </Item>
                    <div>
                        <Item label="ID number" name="idNumber"
                              rules={[{required: true, message: 'ID number is required.'}]}>
                            <Input size="large" placeholder="e.g: 1000000"/>
                        </Item>
                        <div className="flex justify-start items-center">
                            <Checkbox
                                checked={selected === 'idNumber'}
                                onChange={() => setSelected('idNumber')}>
                                Identification number
                            </Checkbox>
                            <Checkbox
                                checked={selected === 'passportNumber'}
                                onChange={() => setSelected('passportNumber')}>
                                Passport number
                            </Checkbox>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                    <Item label="Country" required name="country"
                          rules={[{required: true, message: 'Country is required.'}]}>
                        <Select
                            options={countriesOptions}
                            onChange={value => setSelectedCountry(value)}
                            placeholder="e.g: Namibia" size="large"/>
                    </Item>
                    <Item label="City" required name="city" rules={[{required: true, message: 'City is required.'}]}>
                        <Select
                            disabled={selectedCountry === ''}
                            onChange={value => setSelectedCity(value)}
                            options={citiesOptions(selectedCountry === countries.Zambia ? statics.zambia_cities : selectedCountry === countries.Namibia ? statics.namibia_cities : selectedCountry === countries.South_Africa ? statics.south_africa_cities : [])}
                            placeholder="e.g: Windhoek" size="large"/>
                    </Item>
                    <SuburbField
                        city={selectedCity}
                        onSuburbChange={(value: string) => form.setFieldValue('suburb', value)}
                    />
                    <Item label="Street" required name="street"
                          rules={[{required: true, message: 'Street is required.'}]}>
                        <Input size="large" placeholder="e.g: Bismarck street"/>
                    </Item>
                    <Item label="Erf/Plot number" name="erf">
                        <Input size="large" placeholder="e.g: 1234"/>
                    </Item>
                    <Item name="photo"
                          rules={[{required: true, message: `Please upload driver's photo`}]}>
                        <ImageUpload
                            currentImage={driverPhoto}
                            onLinkReady={(link: string) => (setDriverPhoto(link), form.setFieldValue('photo', link))}
                            label="Driver photo"
                        />
                    </Item>
                </div>

                <div className="my-5">
                    <div className="bg-sky-600 p-3 my-3 rounded-md text-white">
                        <strong>IDENTIFICATION / PASSPORT</strong>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <Item name="idFront"
                              rules={[{required: true, message: 'Please upload the front side of your ID document.'}]}>
                            <ImageUpload
                                currentImage={selectedIdFront}
                                onLinkReady={(link: string) => (setSelectedIdFront(link), form.setFieldValue('idFront', link))}
                                label="Front"
                            />
                        </Item>
                        <Item name="idBack"
                              rules={[{required: true, message: 'Please upload the back side of your ID document.'}]}>
                            <ImageUpload
                                currentImage={selectedIdBack}
                                onLinkReady={(link: string) => (setSelectedIdBack(link), form.setFieldValue('idBack', link))}
                                label="Back"
                            />
                        </Item>
                    </div>
                </div>
                <div>
                    <div className="bg-sky-600 p-3 my-3 rounded-md text-white">
                        <strong>DRIVER LICENSE</strong>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <Item name="dlFront"
                              rules={[{required: true, message: 'Please upload the front side of driver license'}]}>
                            <ImageUpload
                                currentImage={selectedDlFront}
                                onLinkReady={(link: string) => (setSelectedDlFront(link), form.setFieldValue('dlFront', link))}
                                label="Front"
                            />
                        </Item>
                        <Item name="dlBack"
                              rules={[{required: true, message: 'Please upload the back side of driver license'}]}>
                            <ImageUpload
                                currentImage={selectedDlBack}
                                onLinkReady={(link: string) => (setSelectedDlBack(link), form.setFieldValue('dlBack', link))}
                                label="Back"
                            />
                        </Item>

                    </div>
                </div>

                <Item>
                    <div className="w-full my-4">
                        <hr/>
                        {notification.message && (
                            <Alert showIcon message={notification.type} description={notification.message}
                                   type={notification.type}/>
                        )}
                    </div>
                    <div className="justify-end flex items-center">
                        <div>
                            <button type="submit" disabled={loading}
                                    className="primary_button">{loading ? 'Saving ...' : 'Save driver'}</button>
                        </div>
                    </div>
                </Item>
            </Form>
        </div>
    )
}

export default StepAddDriver;