import React, {useEffect, useState, useMemo} from 'react'
import styles from "../../../pages/authentication/authentication.module.scss";
import Axios, {AxiosResponse} from 'axios';
import URLS from "../../../utils/constants/urls";
import {Spin} from 'antd';
import urls from "../../../utils/constants/urls";
import {notification} from 'antd';
import type {NotificationPlacement} from 'antd/es/notification/interface';

const Context = React.createContext({name: 'Default'});

const ResendOtp: React.FC = () => {
    const [showResend, setShowResend] = useState(false);
    const [resending, setResending] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const contextValue = useMemo(() => ({name: 'jc'}), []);

    const openNotification = (placement: NotificationPlacement) => {
        api.info({
            message: `OTP Resent!`,
            duration: 15,
            description:
                <Context.Consumer>{({name}) => ` Please check your messages for the new verification code!`}</Context.Consumer>,
            placement,
        });
    };

    const startCounting = (seconds: number) => {
        setTimeout(() => {
            setShowResend(true)
        }, seconds * 1000)
    }

    const handleResend = async () => {
        const token = window.localStorage.getItem("verificationToken")
        setResending(true)
        try {
            await Axios.post(`${urls?.root_url}${URLS.send_otp}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res: AxiosResponse<any>) => {
                localStorage.setItem('verificationToken', res.data?.result?.token)
                setResending(false)
                openNotification("top")
            })
        } catch (e) {
            setResending(false)
            alert('We can not resend an otp now.')
        }
    }

    useEffect(() => {
        startCounting(5)
    }, [])

    return (
        <>
            <Context.Provider value={contextValue}>
                {contextHolder}
                {showResend && (
                    <div className={styles.form_simple_text_row}>
                        <p>Did not receive the code?
                            <span onClick={handleResend} className="cursor-pointer text-jc-primary mx-1">Resend</span>
                        </p>
                    </div>)}

                {resending && (
                    <div className="flex justify-center items-center p-4">
                        <Spin spinning={resending}/>
                    </div>
                )}
            </Context.Provider>
        </>
    )
}

export default ResendOtp;