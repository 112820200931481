import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

interface Props {
    currentStepIndex: number
    doneSteps: number[]
}

const initialState:Props = {
    currentStepIndex: 0,
    doneSteps: [0]
}


const stepsNavSlice = createSlice({
    name: 'stepNavigation',
    initialState: initialState,
    reducers: {
        updateNavState(state, action: PayloadAction<Record<string, any>>) {
            return {
                ...state,
                ...action.payload
            }
        },
    }
})

export const selectStepNavState = (state: RootState) => state.stepNavigation;
export const {updateNavState} = stepsNavSlice.actions;
export default stepsNavSlice.reducer;