import React, {FC, useState} from 'react';
import DashboardWrapper from "../../Components/widgets/dashboard_wrapper";
import VehiclesTable from "../../Components/widgets/vehicles-table";
import PrimaryButton from "../../Components/widgets/primary-button";
import AddVehicle from "../../Components/forms/add-vehicle";
import {VehicleFormItemProps} from "../../types/onboard-forms/vehicle-props";
import {useNavigate} from "react-router-dom";
import {Card, Modal} from 'antd';
import {UseVehicles} from "../../hooks/useVehicles";
import {UseDrivers} from "../../hooks/useDrivers";


const Vehicles: FC = () => {
    const [showVehicle, setShowVehicle] = useState<boolean>(false)
    const [editableVehicle, setEditableVehicle] = useState<VehicleFormItemProps>()

    const {loadingVehicles, vehicles, refetchVehicles} = UseVehicles()
    const {loadingDrivers, drivers, driversOptions} = UseDrivers()

    const navigate = useNavigate()
    const handleEdit = (id: string) => {
        const found = vehicles.find((item: VehicleFormItemProps) => item._id === id)
        if (found) {
            setEditableVehicle(found)
            setShowVehicle(true)
        }
    }


    const handleAddOpen = () => {
        navigate('/add-vehicle')
    }

    const handleCloseForm = () => {
        setEditableVehicle(undefined)
        setShowVehicle(false)
        refetchVehicles()
    }


    return (
        <DashboardWrapper>
            <div
                className="flex justify-between flex-col md:flex-row md:items-center gap-4 mb-4 bg-white py-3 px-6 md:px-14">
                <span className="page_section_title">Your garage</span>
                <div>
                    <PrimaryButton
                        buttonText="Add new vehicle"
                        onClicked={handleAddOpen}
                        disabled={false}
                    />
                </div>
            </div>
            <div className="md:px-14 px-6">
                <Card title={`${vehicles.length} ${vehicles.length === 1 ? 'vehicle' : 'vehicles'}`}>
                    <VehiclesTable
                        data={vehicles}
                        refetch={refetchVehicles}
                        loading={loadingVehicles}
                        onStartEdit={(id: string) => handleEdit(id)}
                    />
                </Card>
            </div>
            {editableVehicle && (
                <Modal
                    open={showVehicle}
                    onCancel={handleCloseForm}
                    title={editableVehicle ? 'Update Vehicle Details' : 'Add new Vehicle'}
                    footer={null}
                    width={1000}
                >
                    <AddVehicle
                        loadingDrivers={loadingDrivers}
                        drivers={driversOptions}
                        Vehicles={vehicles}
                        editableVehicle={editableVehicle}
                        closeDialog={handleCloseForm}
                    />
                </Modal>
            )}
        </DashboardWrapper>
    )
}

export default Vehicles;