import React from "react";
import {MdCheckCircle} from "react-icons/md";
import {ListTileProps} from "../../types/list-tile";

const ListTile: React.FC<ListTileProps> = ({title, description, checkColor}: ListTileProps) => {
    return (
        <div className="list_tile">
            <MdCheckCircle size={20} color={checkColor} className="list_icon"/>
            <div>
                <h4>{title}</h4>
                <p>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default ListTile;