import DashboardWrapper from "../../Components/widgets/dashboard_wrapper";
import React, {FC, useEffect} from "react";
import {Card} from "antd";
import AddVehicle from "../../Components/forms/add-vehicle";
import {useGetAllVehiclesQuery} from "../../redux/services/vehiclesApi";
import {UseVehicles} from "../../hooks/useVehicles";
import {UseDrivers} from "../../hooks/useDrivers";

const AddVehicleView: FC = () => {
    const {isLoading, data: vehiclesList} = useGetAllVehiclesQuery();

    const {vehicles} = UseVehicles()
    const {loadingDrivers, driversOptions} = UseDrivers()

    return (
        <DashboardWrapper>
            <div className="md:px-14 px-6 md:my-10">
                {!isLoading && vehiclesList && (
                    <Card
                        title="Add new vehicle"
                        bordered={false}
                        style={{width: "100%"}}
                        extra={<a href="/vehicles">View vehicles</a>}
                    >
                        <AddVehicle
                            loadingDrivers={loadingDrivers}
                            drivers={driversOptions}
                            Vehicles={vehicles}/>
                    </Card>
                )}
            </div>
        </DashboardWrapper>
    );
};

export default AddVehicleView;
