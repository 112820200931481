import React, {FC, useEffect, useState} from "react";
import StepFormHead from "../features/onboarding/step-form-head";
import {Alert, Button, Card} from 'antd';
import {useCurrentUser} from "../../hooks/useCurrentUser";
import ImageUpload from "../widgets/image-upload";
import OnboardingNext from "../widgets/onboarding-next";
import userService from "../../services/user.service";
import {FaArrowRight} from "react-icons/fa";
import PrimaryButton from "../widgets/primary-button";
import {useUpdateOnboardStep} from "../../hooks/parner_details_update";
import onboardingSteps from "../../utils/onboarding-steps.json";


const OnboardingStep3: FC = () => {
    const {loading, userInfo} = useCurrentUser()
    const {increment} = useUpdateOnboardStep();
    const [notification, setNotification] = useState<{ message: string, type: 'success' | 'error' }>({
        message: '',
        type: 'success'
    })

    const [saving, setSaving] = useState(false)

    const [initialValues, setInitialValues] = useState({
        documents_driver_license_front: "",
        documents_driver_license_back: "",
        documents_id_document_front: "",
        documents_id_document_back: "",
        documents_bank_confirmation: "",
    })

    useEffect(() => {
        if (userInfo) {
            setInitialValues({
                documents_driver_license_front: userInfo?.driverLicense?.front,
                documents_driver_license_back: userInfo?.driverLicense?.back ? userInfo?.driverLicense?.back : '',
                documents_id_document_front: userInfo?.idDocument?.front ? userInfo?.idDocument?.front : '',
                documents_id_document_back: userInfo?.idDocument?.back ? userInfo?.idDocument?.back : '',
                documents_bank_confirmation: userInfo?.bankConfirmationLetter ? userInfo?.bankConfirmationLetter : '',
            })
        }
    }, [userInfo])


    const handleSubmit = async (values: any, goNext?: boolean) => {
        setSaving(true)
        setNotification({message: '', type: 'success'})
        const payload = {
            idDocument: {
                front: values.documents_id_document_front,
                back: values.documents_id_document_back
            },
            driverLicense: {
                front: values.documents_driver_license_front,
                back: values.documents_driver_license_back
            },
            bankConfirmationLetter: values.documents_bank_confirmation,
            onboardingStatus: 'STEP_4',
        }

        await userService.updatePartnerDetails(payload).then((response) => {
            if (response && response.data.message === 'success') {
                setNotification({message: 'All documents are saved successfully!', type: 'success'})
                setSaving(false)
                if (goNext) {
                    increment(onboardingSteps.indexOf("Business details"))
                }
            } else {
                setNotification({
                    message: `We are not able to save your bank details now, please try again`,
                    type: "error"
                })
                setSaving(false)
            }
        })
    }


    const isFormValid = () => {
        if (initialValues.documents_bank_confirmation
            && initialValues.documents_id_document_front
            && initialValues.documents_driver_license_back
            && initialValues.documents_driver_license_front
            && initialValues.documents_id_document_back
        ) {
            return true
        } else {
            return false
        }
    }

    return (
        <div>
            <StepFormHead
                title="Upload documents"
                subTitle="Please upload the following documents to help us confirm your details."
            />
            <Card>
                <section className="row_section">
                    <div className="upload_documents">
                        <h4>{`Driver's license`}</h4>
                        <div className="doc_section">
                            <ImageUpload
                                currentImage={initialValues.documents_driver_license_front}
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_driver_license_front: link,
                                    })
                                }
                                label="Front"
                            />
                            <ImageUpload
                                currentImage={initialValues.documents_driver_license_back}
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_driver_license_back: link,
                                    })
                                }
                                label="Back"
                            />
                        </div>
                    </div>
                    <div className="upload_documents">
                        <h4>{`National ID or passport`}</h4>
                        <div className="doc_section">
                            <ImageUpload
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_id_document_front: link,
                                    })
                                }
                                label="Front"
                                currentImage={initialValues.documents_id_document_front}
                            />
                            <ImageUpload
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_id_document_back: link,
                                    })
                                }
                                label="Back"
                                currentImage={initialValues.documents_id_document_back}
                            />
                        </div>
                    </div>
                    <div className="upload_documents">
                        <h4>{`Bank confirmation letter`}</h4>
                        <div className="doc_section">
                            <ImageUpload
                                onLinkReady={(link: string) =>
                                    setInitialValues({
                                        ...initialValues,
                                        documents_bank_confirmation: link,
                                    })
                                }
                                label="Front"
                                currentImage={initialValues.documents_bank_confirmation}
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        <hr/>
                        {notification.message && (
                            <Alert
                                message={notification.message}
                                type={notification.type}
                                showIcon
                            />
                        )}
                    </div>
                    <div className="footer_buttons_container w-full">
                        <div className="button_container">
                            <PrimaryButton
                                disabled={!isFormValid()}
                                buttonText={loading ? 'Saving ...' : 'NEXT STEP'}
                                onClicked={() => handleSubmit(initialValues, true)}
                            />
                        </div>
                    </div>
                </section>
            </Card>
        </div>
    );
}

export default OnboardingStep3