import React, {FC, useEffect, useState} from 'react'
import {Form, Card, Select, Input, Tooltip, Alert} from 'antd'
import staticData from "../../utils/constants/static-texts.json";
import {InfoCircleOutlined} from "@ant-design/icons";
import {roleOptions} from "../../utils/helpers";
import userService from "../../services/user.service";
import onboardingSteps from "../../utils/onboarding-steps.json";


const BusinessDetails: FC<{ userDetails: any }> = ({userDetails}) => {
    const [form] = Form.useForm()
    const {Item} = Form
    const [loading, setLoading] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationType, setNotificationType] = useState<'error' | 'success'>('error')
    const businessTipText = `If you don't have a business name, you can put your personal number or name followed by "Deliveries" e.g.: Marta's Deliveries`

    useEffect(() => {
        if (userDetails && userDetails?.businessDetails) {
            form.setFieldsValue({
                businessName: userDetails?.businessDetails?.businessName,
                role: userDetails?.businessDetails?.role,
                city: userDetails?.businessDetails?.city
            })
        }
    }, [userDetails])

    const handleSubmit = async (values: any) => {
        setLoading(true)
        const payload = {
            businessDetails: {
                businessName: values.businessName,
                role: values.role,
                city: values.city
            },
        }

        await userService.updatePartnerDetails(payload).then((response) => {
            if (response && response.data.message === 'success') {
                setNotificationMessage('Information saved successfully!')
                setNotificationType('success')
            } else {
                setNotificationMessage(`We couldn't save your information now, please try again later.`)
                setNotificationType('error')
            }
        })
        setLoading(false)
    }

    return (
        <Card>
            <Form
                form={form}
                layout="vertical"
                wrapperCol={{span: 24}}
                labelCol={{span: 24}}
                onFinish={handleSubmit}
                disabled={!userDetails}
            >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <Item
                        name="businessName"
                        rules={[{required: true, message: 'Business name is required.'}]}
                        label="Business name"
                    >
                        <Input
                            size="large"
                            placeholder="e.g: Jabu Logistics"
                            suffix={
                                <Tooltip title={businessTipText}>
                                    <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
                                </Tooltip>
                            }
                        />
                    </Item>
                    <Item
                        name="city"
                        rules={[{required: true, message: 'Business city is required.'}]}
                        label="City"
                    >
                        <Select
                            showSearch
                            placeholder="e.g: Windhoek"
                            optionLabelProp="name"
                            optionFilterProp="name"
                            size="large"
                            options={userDetails?.country && userDetails?.country === 'Zambia' ? staticData.zambia_cities : userDetails?.country === 'South Africa' ? staticData.south_africa_cities : staticData.namibia_cities}
                        />
                    </Item>
                    <Item
                        name="role"
                        rules={[{required: true, message: 'Business role is required.'}]}
                        label="Your role in business"
                    >
                        <Select
                            showSearch
                            placeholder="e.g: Owner"
                            size="large"
                            options={roleOptions}
                        />
                    </Item>
                </div>
                <Item className="col-span-2">
                    <hr/>
                    {notificationMessage && (
                        <Alert message={notificationMessage} type={notificationType} showIcon/>
                    )}
                </Item>
                <div className="flex justify-end items-center">
                    <div className="md:w-2/12">
                        <button
                            type="submit"
                            className="primary_button"
                            disabled={loading}
                        >
                            {loading ? 'Please wait ...' : 'Save changes'}
                        </button>
                    </div>
                </div>
            </Form>
        </Card>
    )
}

export default BusinessDetails