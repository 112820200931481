import React from 'react';

const Terms = () => {
    return (
        <div className="terms_wrapper">
            <span>Last modified: February 23, 2023</span>
            <section>
                <h3>Section 1</h3>
                <strong>
                    Contractual Relationships
                </strong>
                <p>
                    {`
                     These Terms of Use (“Terms”) govern the access or use by you, an individual or entity, of the applications (including mobile applications), websites, content, products, and services (collectively, the “Services”) made available by Jabu Logistics (PTY) LTD and its subsidiaries and affiliates (collectively, “Jabu Logistics (PTY) LTD”). PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING AND USING THE SERVICES. In these Terms, the words “including” and “include” mean “including, but not limited to.
                     Your access and use of the Services constitutes your agreement to be bound by these Terms, which establishes a contractual relationship between you and Jabu Logistics (PTY) LTD. These Terms supersede any other agreements with respect to the subject matter of these Terms, except that with respect to any motor carrier agreement between the carrier you represent (as applicable) and Jabu Logistics (PTY) LTD, such agreement shall not be superseded by these Terms, and to the extent that such other agreement is inconsistent with these Terms, such other agreement will govern. Jabu Logistics (PTY) LTD may, at any time for any reason, without notice to you, cease offering you access to the Services or any portion thereof or deny you access to the Services or any portion thereof.
                     You agree to maintain all your login credentials to access or use the Services in confidence and only permit your authorized users to access or use the Services. You are responsible for all activity that occurs under your login credentials.
                     Jabu Logistics (PTY) LTD may amend these Terms from time to time. Amendments will be effective upon Jabu Logistics (PTY) LTD’s posting of such updated Terms at this location. Your continued access or use of the Services after such posting constitutes your consent to be bound by the Terms, as amended.
                     Please refer to the Jabu Logistics (PTY) LTD Privacy Notice located here for information about our collection and use of personal information in connection with the Services.
                    `}
                </p>
            </section>
            <section>
                <h3>Section 2</h3>
                <strong>
                    License
                </strong>
                <p>
                    {`
                     Subject to your compliance with these Terms, Jabu Logistics (PTY) LTD grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to access and use the Services. Any rights not expressly granted herein are reserved by Jabu Logistics (PTY) LTD (and its affiliates, as applicable) and Jabu Logistics (PTY) LTD’s licensors.
                    `}
                </p>
            </section>
            <section>
                <h3>Section 2</h3>
                <strong>
                    License
                </strong>
                <p>
                    {`
                     Subject to your compliance with these Terms, Jabu Logistics (PTY) LTD grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to access and use the Services. Any rights not expressly granted herein are reserved by Jabu Logistics (PTY) LTD (and its affiliates, as applicable) and Jabu Logistics (PTY) LTD’s licensors.
                    `}
                </p>
                <strong>
                    Restrictions
                </strong>
                <p>
                    {`
                     You may not, and may not authorize others to: (i) remove any copyright, trademark or other proprietary notices from any portion of the Services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by Jabu Logistics (PTY) LTD; (iii) decompile, disassemble, reverse engineer or otherwise attempt to derive the source code or underlying technology, methodologies or algorithms of the Services, except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of the Services; (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services, or unduly burdening or hindering the operation and/or functionality of any aspect of the Services; or (vi) attempt to gain unauthorized access to or impair any aspect of the Services or their related systems or networks.
                    `}
                </p>
                <strong>
                    Ownership
                </strong>
                <p>
                    {`
                     The Services and all rights therein are and shall remain Jabu Logistics (PTY) LTD’s property or the property of Jabu Logistics (PTY) LTD’s licensors. Neither these Terms nor your use of the Services convey or grant to you any rights: (i) in or related to the Services except for the limited license granted above; or (ii) to use or reference in any manner Jabu Logistics (PTY) LTD’s company names, logos, product and service names, trademarks or services marks or those of Jabu Logistics (PTY) LTD’s licensors.
                    `}
                </p>
            </section>
            <section>
                <h3>Section 3</h3>
                <strong>
                    Disclaimers; Limitation of Liability; Indemnity.
                </strong>
                <strong>
                    DISCLAIMER
                </strong>
                <p>
                    {`
                      THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” JABU LOGISTICS (PTY) LTD DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, JABU LOGISTICS  (PTY) LTD MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR ACCESS OR USE OF THE SERVICES REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
                    `}
                </p>
                <strong>
                    LIMITATION OF LIABILITY
                </strong>
                <p>
                    {`
                     JABU LOGISTICS (PTY) LTD SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, EVEN IF JABU LOGISTICS (PTY) LTD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. JABU LOGISTICS (PTY) LTD SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF JABU LOGISTICS (PTY) LTD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. JABU LOGISTICS (PTY) LTD SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND JABU LOGISTICS (PTY) LTD’S REASONABLE CONTROL.
                      JABU LOGISTICS (PTY) LTD DOES NOT CONTROL, ENDORSE OR TAKE RESPONSIBILITY FOR ANY USER CONTENT OR THIRD-PARTY CONTENT AVAILABLE ON OR LINKED TO BY THE SERVICES (INCLUDING INFORMATION ON THE SERVICES PROVIDED BY USERS). JABU LOGISTICS (PTY) LTD CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                    `}
                </p>
                <strong>
                    Indemnity
                </strong>
                <p>
                    {
                        `You agree to indemnify and hold Jabu Logistics (PTY) LTD and its affiliates and its and their respective officers, directors, employees, and agents harmless from any and all claims, demands, losses, liabilities, and expenses (including attorneys’ fees), arising out of or in connection with: (i) your use of the Services; and/or (ii) your breach or violation of any of these Terms.`
                    }
                </p>
            </section>
            <section>
                <h3>Section 4</h3>
                <strong>
                    Dispute Resolution
                </strong>
                <br/>
                <span>
                    Arbitration
                </span>
                <p>
                    {`
                     You agree that any dispute, claim or controversy arising out of or relating to these Terms, including breach, termination, enforcement, interpretation or validity thereof or the use of the Services (collectively, “Disputes”) must be solely and finally resolved by confidential and final arbitration between you and Jabu Logistics (PTY) LTD, except that each party retains the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights. You acknowledge and agree that you and Jabu Logistics (PTY) LTD are each waiving the right to a trial by jury or to participate as a plaintiff or class in any purported class action or representative proceeding. Further, unless both you and Jabu Logistics (PTY) LTD otherwise agree in writing, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of any class or representative proceeding. If this specific paragraph is held unenforceable, then the entirety of this “Dispute Resolution” section will be deemed void. Except as provided in the preceding sentence, this “Dispute Resolution” section will survive any termination of these Terms.
                     Arbitration Rules and Governing Law
                     The arbitration will be administered, governed and interpreted in accordance with the laws of Namibia, or other location as determined by Jabu Logistics (PTY) LTD.  
                    `}
                </p>
                <strong>
                    Negotiation
                </strong>
                <p>
                    {
                        `Prior to initiating an arbitration, you and Jabu Logistics (PTY) LTD each agree to notify the other party in writing of any dispute and to attempt to negotiate an informal resolution. 
                         Notice to Jabu Logistics (PTY) LTD must be sent to Jabu Logistics (PTY) LTD, Attn:  JabuConnect Program.
                        `
                    }
                </p>
            </section>
            <section>
                <h3>Section 5</h3>
                <strong>
                    Other Provision
                </strong>
                <br/>
                <span>
                    Text Messaging and Telephone Calls
                </span>
                <p>
                    {`
                     You agree that Jabu Logistics (PTY) LTD may contact you by telephone or SMS messages (including by an automatic telephone dialing system and/or with an artificial or pre-recorded voice) at any of the phone numbers provided by you or on your behalf, including for marketing purposes. You understand that you are not required to provide this consent as a condition of purchasing any property, goods or services. You also understand that you may opt out of receiving SMS messages from Jabu Logistics (PTY) LTD at any time, by notifying Jabu Logistics (PTY) LTD.
                    `}
                </p>
                <span>
                 User Provided Content
                </span>
                <p>
                    {`
                     Jabu Logistics (PTY) LTD may, in Jabu Logistics (PTY) LTD’s sole discretion, permit you from time to time to submit, upload, publish or otherwise make available to Jabu Logistics (PTY) LTD through the Services textual, audio, visual or other content and information, including load information, commentary and feedback related to the Services, ratings and reviews (including of motor carriers and facilities), initiation of support requests, and submission of entries for competitions and promotions (“User Content”). Any User Content provided by you remains your property. However, by providing User Content to Jabu Logistics (PTY) LTD, you grant Jabu Logistics (PTY) LTD a worldwide, perpetual, irrevocable, transferable, royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels now known or hereafter devised (including in connection with the Services and Jabu Logistics (PTY) LTD’s business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.
                     You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have all rights, licenses, consents and releases necessary to grant Jabu Logistics (PTY) LTD the license to the User Content as set forth above; and (ii) neither the User Content, nor your submission, uploading, publishing or otherwise making available of such User Content, nor Jabu Logistics (PTY) LTD’s use of the User Content as permitted herein will infringe, misappropriate or violate a third party’s intellectual property or proprietary rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
                     You agree to not provide User Content that is defamatory, libelous, hateful, violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by Jabu Logistics (PTY) LTD in its sole discretion, whether or not such material may be protected by law. Jabu Logistics (PTY) LTD may, but shall not be obligated to, review, monitor, or remove User Content, at Jabu Logistics (PTY) LTD’s sole discretion and at any time and for any reason, without notice to you.
                    `}
                </p>
                <span>
                 Network Access and Devices
                </span>
                <p>
                    {`
                     You are responsible for obtaining the data network access necessary to use the Services. Your mobile network’s data and messaging rates and fees may apply if you access or use the Services from your device. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and applications and any updates thereto. Jabu Logistics (PTY) LTD does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. In addition, the Services may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.
                    `}
                </p>
                <span>
                 Choice of Law
                </span>
                <p>
                    {`
                     These Terms are governed by and interpreted in accordance with the laws of Namibia.
                    `}
                </p>
                <span>
                 Notification of Copyright Infringement
                </span>
                <p>
                    {`
                     It is Jabu Logistics (PTY) LTD’s policy, in appropriate circumstances and at its discretion, to disable and/or terminate the accounts of users who repeatedly infringe or are repeatedly charged with infringing the copyrights or other intellectual property rights of others.
                    `}
                </p>
                <span>
                 Notice
                </span>
                <p>
                    {`
                    Jabu Logistics (PTY) LTD may give notice by means of a general notice on the Services, electronic mail to your email address in your Account, or by written communication sent by first class mail or pre-paid post to your address in your Account. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing. You may give notice to Jabu Logistics (PTY) LTD, with such notice deemed given when received by Jabu Logistics (PTY) LTD, at any time by first class mail or pre-paid post to Jabu Logistics (PTY) LTD, Attn: JabuConnect Program 
                    `}
                </p>
                <span>
                General
                </span>
                <p>
                    {`
                    You may not assign these Terms without Jabu Logistics (PTY) LTD’s prior written approval. Jabu Logistics (PTY) LTD may assign these Terms without your consent to: (i) a subsidiary or affiliate; (ii) an acquirer of Jabu Logistics (PTY) LTD’s equity, business or assets; or (iii) a successor by merger. Any purported assignment in violation of this section shall be void. No joint venture, partnership, employment, or agency relationship exists between you, Jabu Logistics (PTY) LTD or any third party as a result of these Terms or use of the Services. If any provision of these Terms is held to be invalid or unenforceable, such provision shall be sVehicle and the remaining provisions shall be enforced to the fullest extent under law. Jabu Logistics (PTY) LTD’s failure to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Jabu Logistics (PTY) LTD in writing.
                    `}
                </p>
                <span>
                Choice of Language
                </span>
                <p>
                    {`
                    It is the express wish of the parties that these terms and conditions be drawn up in English. These terms may be translated into other languages. In the event of a difference in interpretation between the English version of these terms and another version, the English version shall prevail.
                    `}
                </p>
            </section>
        </div>
    )
}

export default Terms;