import URLS from "../utils/constants/urls";
import Axios from "axios";

export const preSignFileUrl = async (fileType: string) => {
    const accessToken = localStorage.getItem("accessToken");
    const BASE_URL = `${URLS.root_url}`;
    let url = '';
    try {
        const preSignedUrl = await Axios.get(`${BASE_URL}/media/signed-url`, {

            headers: {['Content-Type']: fileType, Authorization: `Bearer ${accessToken}`},
            data: null,
        });
        url = preSignedUrl.data.url;
    } catch (error) {
        console.log(error)
    }
    return url;
}

export const updateSignedMedia = async (url: string, fileData: File) => {
    const result = await Axios.put(url, fileData)
        .catch(error => console.error(error.response.data, {request: error.request}));
    return result;
}
