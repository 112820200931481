import React, {FC} from "react";
import {MdClose, MdDelete, MdUpload} from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Button, Spin, Alert} from "antd";
import {preSignFileUrl, updateSignedMedia} from "../../services/file-upload";
import ThumbPreview from "../features/thumb-preview";
import {BsFillFileEarmarkArrowUpFill} from "react-icons/bs";

interface ImageUploadProps {
    label: string;
    onLinkReady: any;
    currentImage?: string;
}

const ImageUpload: FC<ImageUploadProps> = ({
                                               label,
                                               onLinkReady,
                                               currentImage,
                                           }) => {
    const [uploadOpen, setUploadOpen] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

    const handleImageSelectChange = (
        filesEvent: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (filesEvent.target.files && filesEvent.target.files?.length > 0) {
            setSelectedFile(filesEvent.target.files[0]);
        }
    };

    const handleImageUpload = async () => {
        setUploading(true);
        if (selectedFile) {
            preSignFileUrl(selectedFile?.type).then((url: string) => {
                const onlyImageUrl = url ? url.split("?")[0] : "";
                updateSignedMedia(url, selectedFile).then(() => {
                    onLinkReady(onlyImageUrl);
                    setUploadOpen(false);
                    setUploading(false);
                    setSelectedFile(null);
                });
            });
        }
        //     update the with real file
    };

    return (
        <div className="image_upload_section">
            <div
                className="image_upload_box overflow-hidden"
                onClick={() => setUploadOpen(true)}
            >
                <ThumbPreview currentFile={currentImage}/>
            </div>
            <Dialog
                open={uploadOpen}
                onClose={() => setUploadOpen(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    <div className="flex justify-between items-center cursor-pointer">
                        <span className="font-bold">{uploading ? "Uploading" : "Upload file"}</span>
                        <MdClose onClick={() => setUploadOpen(false)}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="px-4 md:px-10 max-w[400px] min-w-[200px]">
                        {uploading ? (
                            <div className="py-10 md:px-20">
                                <Spin tip="Please wait ...">
                                </Spin>
                            </div>
                        ) : (
                            <>
                                {!selectedFile && currentImage && (
                                    <div className="w-[220px] md:w-full">
                                        <ThumbPreview currentFile={currentImage}/>
                                    </div>
                                )}
                                {selectedFile ? (
                                    <div>
                                        {selectedFile?.type !== "application/pdf" ? (
                                            <div>
                                                <img
                                                    src={URL.createObjectURL(selectedFile)}
                                                    alt="selected File"
                                                    className="object-center rounded-md relative w-[220px] md:w-full"
                                                />
                                            </div>
                                        ) : (
                                            <span className="font-bold text-xl">
                        {selectedFile?.name}
                      </span>
                                        )}
                                        <br/>
                                        <button
                                            className="delete_button flex items-center my-4"
                                            onClick={() => setSelectedFile(null)}
                                        >
                                            <MdDelete/> Remove
                                        </button>
                                    </div>
                                ) : (
                                    <div
                                        className="flex gap-3 w-[220px] md:w-full justify-center items-center flex-wrap flex-row">
                                        <label htmlFor="dropzone-file">
                                            <div
                                                className="w-full flex flex-col items-center justify-center bg-sky-50 py-2 px-10 cursor-pointer rounded border-dashed border-2 border-jc-primary">
                                                <BsFillFileEarmarkArrowUpFill className="w-10 h-10 my-5"/>
                                                <p className="font-medium text-xl">Choose file to upload</p>
                                                <p className="text-gray-700 text-center">
                                                    <span>Allowed file formats for upload:</span>
                                                    <br/>
                                                    <strong className="bg-gray-200 rounded p-1">PDF</strong>, <strong
                                                    className="bg-gray-200 rounded p-1">JPEG</strong> or <strong
                                                    className="bg-gray-200 rounded p-1">PNG</strong></p>
                                            </div>
                                            <input
                                                id="dropzone-file"
                                                type="file"
                                                className="hidden"
                                                accept=".jpeg, .png, .jpg, .pdf"
                                                onChange={handleImageSelectChange}
                                            />
                                        </label>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    {!uploading && selectedFile && (
                        <div className="py-5 px-7 w-full">
                            <Button
                                block
                                size="large"
                                type="primary"
                                onClick={handleImageUpload}
                            >
                                Upload
                            </Button>
                        </div>
                    )}
                </DialogActions>
            </Dialog>
            <strong>{label}</strong>
        </div>
    );
};

export default ImageUpload;
