import React from 'react';
import 'antd/dist/reset.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {ConfigProvider} from 'antd'
import './styles/global.scss';
import 'react-toastify/dist/ReactToastify.css';
import {store, persistor} from './redux/store';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import PublicRoutes from "./Components/features/navigation/public-routes";
import ProtectedRoutes from "./Components/features/navigation/protected-routes";

import ReactGA from 'react-ga';
ReactGA.initialize('G-516HXT85NT');
ReactGA.pageview(window.location.pathname + window.location.search);


const AppManager = () => {
    const userToken = localStorage.getItem('accessToken');
    if (userToken && userToken !== 'undefined') {
        return <ProtectedRoutes/>
    } else {
        return <PublicRoutes/>
    }
}

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#2D4669',
                    colorPrimaryText: "#FFF"
                },
            }}
        >
            <Provider store={store}>
                <PersistGate persistor={persistor} loading={null}>
                    {AppManager}
                </PersistGate>
            </Provider>
        </ConfigProvider>
    )
}

export default App;