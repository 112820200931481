import styles from "../authentication/authentication.module.scss";
import images from "../../utils/constants/assets";
import React from "react";
import staticTexts from '../../utils/constants/static-texts.json'
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {Steps} from 'antd';
import {logoutUser} from "../../utils/helpers";
import {MdLogout} from "react-icons/md";
import {useNavigate} from "react-router-dom";

const Review = () => {
    const {userInfo} = useCurrentUser();
    const navigate = useNavigate();
    const manageSteps = () => {
        let step;
        switch (userInfo?.verificationStatus) {
            case "NOT_VERIFIED":
                step = 0
                break
            case "IN_PROGRESS":
                step = 1
                break
            case "APPROVED":
                step = 2
                break
            default:
                step = 0
        }
        if (userInfo?.onboardingStatus === "DONE_ONBOARDING") {
            step = 1
        }
        return step;
    }


    if (userInfo && userInfo?.verificationStatus === 'APPROVED') {
        navigate('/dashboard')
    }


    return (
        <div>
            <div
                className="flex absolute justify-end w-full md:px-20 px-5 bg-jc-primary text-jc-primary md:py-4 py-4 z-50">
                <div onClick={logoutUser}
                     className="flex justify-between items-center gap-3 hover:bg-sky-200 cursor-pointer bg-white px-4 py-2 rounded-full">
                    <MdLogout/>
                    <span>logout</span>
                </div>
            </div>
            <div className="bg-jc-primary h-[500px] flex justify-center items-center">
                <div className="w-full flex justify-center pb-10 md:pb-0">
                    <div className="bg-white shadow-md md:w-6/12 w-11/12 rounded-xl md:mt-64 mt-96">
                        <div className="p-5 md:px-14">
                            <div className={styles.logo_container}>
                                <img src={images.mainLogo} alt="jabu-driver"/>
                            </div>
                            <div className="text-jc-primary mb-10 font-bold text-xl">
                                <p>Onboarding progress</p>
                            </div>
                            <div className="grid grid-cols-1 gap-3">
                                <Steps
                                    direction="vertical"
                                    current={manageSteps()}
                                    items={staticTexts.review_steps}
                                />
                                {userInfo?.verificationStatus === 'CANCELED' ? (
                                    <div className="bg-red-400 rounded p-4 text-white">
                                        Your application has been rejected.
                                    </div>
                                ) : ''}
                            </div>
                            {userInfo && !userInfo.idDocument || !userInfo?.bankConfirmationLetter || userInfo.onboardingStatus !== 'DONE_ONBOARDING' ? (
                                <a href="/onboarding">
                                    <button
                                        type="button"
                                        className="primary_button"
                                        disabled={false}
                                    >
                                        Continue account setup
                                    </button>
                                </a>
                            ) : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Review;