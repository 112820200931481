//  the purpose of this component is only to verify the otp code, and share
// the response with parent pages (sign up and login page)
import React, {FC, useState} from 'react';
import Alert from '@mui/material/Alert'
import OTPInputField from "../../widgets/otp-input-field";
import {useVerifyOtpMutation} from "../../../redux/services/verificationApi";
import Axios from "axios";
import urls from "../../../utils/constants/urls";
import ResendOtp from "./resend-otp";

interface Props {
    currentPhones: string
    loading: boolean
    onSucceed: any
    isLogin?: boolean
}

const VerifyOtpForm: FC<Props> = ({loading, onSucceed, isLogin}: Props) => {
    const [userCode, setUserCode] = useState<string[]>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [verifyOtp, {isLoading: verifyingOtp}] = useVerifyOtpMutation();

    const isValid = () => {
        if (userCode?.length === 5 && !verifyingOtp) {
            return true
        }
    }

    const buttonText = loading ? 'Please wait ...' : 'Verify';

    const getProfile = async (token: string) => {
        if (isLogin) {
            const user = await Axios.get(`${urls?.root_url}/partners/me`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            if (user.data?.result?.verificationStatus) {
                localStorage.setItem('vStatus', user.data?.result?.verificationStatus)
                onSucceed()
            }
        } else {
            onSucceed()
        }
    }
    const handleVerify = () => {
        setErrorMessage(undefined);
        const payload = {
            code: userCode
        }
        if (isValid()) {
            verifyOtp(payload).then((res: any) => {
                if (res && res.data?.message === 'success') {
                    const token = res.data?.result?.token;
                    localStorage.setItem('accessToken', token)
                    getProfile(token).then((response: any) => {
                        console.log(response)
                    })
                } else {
                    setErrorMessage('Invalid OTP code, you can try again or click resend for new code!')
                }
            });
        }
    }

    return (
        <div className="flex flex-col space-y-5">
            <div>
                <OTPInputField
                    handleSubmission={handleVerify}
                    handleInputs={(values: string[]) => setUserCode(values)}
                />
            </div>
            {errorMessage && (
                <Alert severity="error">{errorMessage}</Alert>
            )}
            <button
                type="button"
                onClick={handleVerify}
                className={`primary_button ${
                    !isValid() ? 'disabled_comp' : ''
                }`}
                disabled={!isValid()}
            >
                {buttonText}
            </button>
            <ResendOtp />
        </div>
    )
}

export default VerifyOtpForm;